@charset "UTF-8";
/*
 best-practice breakpoints
 */
:root {
  --e-global-color-primary: #864289;
  --e-global-color-secondary: #E72462;
  --e-global-color-text: #000000;
  --e-global-color-accent: #61CE70;
  --e-global-typography-primary-font-family: "Poppins";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Poppins";
  --e-global-typography-text-font-size: 18px;
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  color: #000000;
  --font-base: "Poppins",sans-serif;
}

/*
 default FSC styles
 these styles need to be adjusted when
 Configuration/TypoScript/Constants/Plugin/0.fsc.constants.typoscript
 is adjusted
 */
/**
 * Auto Generated by OMGF
 * @author: Daan van den Bergh
 * @url: https://daan.dev
 */
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/karla-italic-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/karla-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-100.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-100.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-200.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-200.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-300.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-500.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-500.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-600.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-600.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-700.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-800.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-800.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-ext-900.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/poppins-italic-latin-900.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-100.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-100.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-200.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-200.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-300.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-500.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-500.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-600.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-600.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-700.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-800.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-800.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-ext-900.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/poppins-normal-latin-900.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-ext-100.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-100.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-ext-300.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-ext-500.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-500.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-ext-700.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-ext-900.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/roboto-italic-latin-900.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-ext-100.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-100.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-ext-300.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-ext-500.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-500.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-ext-700.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-ext-900.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/roboto-normal-latin-900.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin-ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../Fonts/roboto-slab-normal-latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* ------------------------------------------------------------------------- *\

	css layout formatting for Content First, static footer, sections, responsive! · template
	written by the left hand of mtness

	2022-02-15

*/
/* ------------------------------------------------------------------------- */ /* global layout */
* {
  margin: 0;
  padding: 0;
}

img, div {
  border: 0;
}

html {
  overflow-x: hidden; /* due to .start.deco.h1 */
  /* overflow-y: hidden; /* TODO find fix for overlapping fixed header */
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  min-height: 100%;
  font-family: var(--font-base);
  font-size: var(--size-step-0);
  line-height: 1.75;
  background: #fff;
  color: #1E1B1B;
  opacity: 1;
  transition: 1s opacity;
  background: #fff;
  display: flex;
  flex-flow: column;
}

body.fadein {
  opacity: 0;
  transition: none;
}

/* ------------------------------------------------------------------------- */
.center {
  position: relative;
  width: 100%;
  max-width: 1410px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 575px) {
  #content > .center {
    padding: 0 20px;
  }
}
/* ------------------------------------------------------------------------- */ /* sections & content */
#content {
  background: #fcfcfc;
  color: #4D4D4D;
  padding: 122px 0 0;
}

@media screen and (max-width: 575px) {
  #content {
    padding-top: 90px;
  }
}
#content > div:not(.center) {
  margin: 0 -20px;
}

@media screen and (max-width: 575px) {
  #content > div:not(.center) {
    margin: 0;
  }
}
#content > .section:first-of-type {
  padding: 42px 0;
}

@media screen and (max-width: 768px) {
  #content > .section:first-of-type {
    padding: 25px 0 50px 0;
  }
}
.section.first h1 {
  font-size: 3rem;
  font-weight: 500;
  color: #3f6bb3;
  text-align: center;
  padding-bottom: 50px;
  text-transform: uppercase;
}

.section.first h2 {
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  color: #232323;
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .section.first h2 {
    font-size: 1.1em;
  }
}
.section.first p {
  line-height: 1.75;
  padding-bottom: 0.5em;
  max-width: 960px;
  margin: 0 auto;
}

.section.first ul {
  margin: 0 0 1em 1em;
}

.section.first ul li {
  padding-bottom: 0.5em;
}

/* ------------------------------------------------------------------------- */
.section.grey-bg {
  padding: 50px 0;
  background-color: #F2F2F2;
  text-align: center;
}

/* ------------------------------------------------------------------------- */
.section.cta {
  padding: 150px 0 50px;
  text-align: center;
  background-color: #fff;
}

.cta .frame-default a {
  position: relative;
  border: 1px solid #3f6bb3;
  border-radius: 0.5em;
  padding: 0.8em 3em;
  background-color: #3f6bb3;
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

/*
.cta .frame-default a:after {
	content: "";
	margin: 0 0 0 8px;
	display: inline-block;
	width: 12px;
	height: 12px;
	background: ;
}
*/
.cta .frame-default a:hover {
  background-color: #fff;
  color: #3f6bb3;
}

/*
.cta .frame-default a:after:hover {
	color: #F57321;
}
*/
@media screen and (max-width: 768px) {
  .cta .frame-default a {
    font-size: 1em;
    padding: 0.5em 1em;
  }
}
/* ------------------------------------------------------------------------- */ /* atf content styling */
#atf {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: max(200px, 38vh - 100px);
  text-align: center;
  color: #fff;
}

.pagets__index #atf {
  height: calc(80vh - 100px);
}

#atf h1, #atf .h1, #atf h2, #atf .h2, #atf h3, #atf .h3, #atf h4, #atf .h4 {
  padding-bottom: 1em;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .pagets__index #atf {
    /*height: unset;*/
  }
  #atf h1 {
    font-size: 24px;
    line-height: 1.33;
    padding-top: 0.5em;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 960px) {
  .pagets__index #atf {
    height: 100%;
  }
  #atf h1 {
    padding-top: 0.75em;
    padding-bottom: 0.25em;
    font-weight: 600;
  }
  #atf h1 br {
    display: none;
  }
}
@media screen and (min-width: 960px) and (max-width: 1366px) {
  #atf {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 1680px) {
  #atf h1 {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}
#atf .timer {
  line-height: 48px;
  padding-bottom: 24px;
}

#atf .atf-nav {
  display: flex;
}

@media screen and (max-width: 768px) {
  #atf .timer {
    padding-bottom: 0;
  }
  #atf .atf-nav {
    flex-flow: column-reverse;
  }
}
#atf .atf-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  height: 100%;
}

#atf .atf-nav > div {
  margin: 1rem;
  width: 28vw;
  max-width: 18rem;
  font-size: 1.33rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  border-radius: 2rem;
  /* border: 1px solid #ffffff22; */
}

@media screen and (max-width: 768px) {
  #atf .atf-nav > div {
    width: 80vw;
  }
}
#atf .atf-nav .blue {
  background-color: #3f6bb3;
}

#atf .atf-nav .red {
  background-color: #e0011a;
}

#atf .atf-nav > div:hover {
  background-color: #fff;
  color: #013B4F;
  /* border: 1px solid #ffffff33; */
}

#atf .atf-nav .blue a:hover {
  color: #3f6bb3;
}

#atf .atf-nav .red a:hover {
  color: #e0011a;
}

@media screen and (max-width: 1680px) {
  #atf .atf-nav > div {
    margin: 0.5em 1em;
  }
}
#atf h3 {
  max-width: 50vw;
  font-size: 42px;
  font-weight: normal;
  line-height: 48px;
  padding-top: 2em;
  display: none;
}

.pagets__index #atf h3 {
  display: block;
}

#atf h3 strong {
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  #atf h3 {
    display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 1680px) {
  #atf h3 {
    padding-top: 0.5em;
    max-width: 80vw;
  }
}
@media screen and (min-width: 1681px) {
  #atf h3 {
    padding-top: 0;
  }
}
/* ------------------------------------------------------------------------- */
.tm-yt h3 {
  font-weight: 600;
  font-size: 4em;
}

.tm-yt h4 {
  font-size: 2.5em;
}

.tm-yt h5 {
  padding-top: 1em;
  font-size: 1.75em;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .tm-yt h3 {
    font-size: 1.5em;
  }
  .tm-yt h4 {
    font-size: 1.25em;
  }
  .tm-yt .ce-gallery iframe {
    width: 100%;
    height: auto;
  }
  .tm-yt h5 {
    font-size: 1em;
  }
}
/* ------------------------------------------------------------------------- */ /* timer */
#timer .frame.default {
  margin-bottom: 0;
}

.timer.j-timer-first {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  font-weight: 300;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}

header #date .timer span {
  line-height: 1;
}

.timer b {
  font-weight: 100;
  line-height: 1;
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 16px 48px;
  position: relative;
}

.timer i {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
}

.timer b:not(:last-child)::before {
  content: ":";
  position: absolute;
  left: 85%;
  top: 40%;
  font-size: 3rem;
  transform: translate(15px, -50%);
}

@media (max-width: 1024px) {
  .timer.j-timer-first {
    font-size: 2em;
    margin-top: 0;
  }
  .timer b {
    padding-left: 16px;
    padding-right: 16px;
  }
  .timer i {
    font-size: 11px;
  }
  .timer b:not(:last-child)::before {
    font-size: 2rem;
  }
}
/* ------------------------------------------------------------------------- */ /* page register */
.frame-default .register h1 {
  font-size: 2em;
  text-align: left;
  padding: 2em 0;
}

.register fieldset > legend {
  padding: 1em 0;
}

/* ------------------------------------------------------------------------- */ /* newsletter */
.frame-default .tx-fp-newsletter h1 {
  font-size: 2em;
  text-align: left;
  padding: 2em 0 0;
}

.tx-fp-newsletter .ui.checkbox label {
  font-size: 0.75em;
  line-height: 1.33;
  max-width: 80%;
}

/* ------------------------------------------------------------------------- */ /* bootstrap adjustments */
#auth-error {
  text-align: center;
  background-color: #fff;
  color: #E62462;
  padding: 0.5rem;
}

.login label {
  font-size: 0.8rem;
  display: inline-block;
  margin-bottom: 0.25rem;
}

.login input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%;
  padding: 0.5rem 3rem;
}

.login .forgot-pass {
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
}

.login .forgot-pass:hover {
  text-decoration: underline;
}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */ /* footer */
footer {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  min-height: 90px;
  overflow: hidden;
}

footer > .row {
  display: flex;
  padding: 25px 20px;
}

footer > .row > .center {
  max-width: 1410px;
  margin: 0 auto;
  padding: 0;
  display: grid;
  align-items: center;
}

footer > .menus.row > .center {
  /*padding: 0 25px;*/
}

footer h5 {
  font-size: var(--size-step-0);
  font-weight: normal;
  color: #4D4D4D;
}

footer .contact {
  grid-area: contact;
}

footer .openings {
  grid-area: openings;
}

footer .links {
  grid-area: links;
}

footer .copy {
  grid-area: copy;
}

footer .social {
  grid-area: social;
}

footer .legal {
  grid-area: legal;
}

@media screen and (max-width: 1320px) {
  footer > .row > .center {
    max-width: 1240px;
  }
}
footer .logos ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

footer .logos h2 { /* position: absolute; bottom: 0; right: 342px; width: 200px; */
  color: #BBBDBF;
  font-weight: normal;
  font-size: 10px;
  padding: 0 0 15px 0;
  text-align: center;
}

footer .logos li {
  display: inline-block;
  padding: 0;
}

footer .logos li a {
  display: block;
  position: relative;
  overflow: hidden;
  height: 0;
}

@media screen and (max-width: 768px) {
  footer .logos ul {
    flex-flow: column;
  }
  footer .logos li {
    margin: 1em 0;
  }
}
footer > .row.bottom > .center {
  display: grid;
  grid-template-areas: "copy social legal";
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center;
  justify-content: space-around;
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  footer > .row.bottom > .center {
    grid-template-areas: "social social" "copy legal";
    grid-template-rows: auto;
    grid-template-columns: auto;
    row-gap: 25px;
    justify-content: space-between;
  }
}
@media screen and (max-width: 575px) {
  footer > .row {
    padding: 1em 0;
    text-align: center;
  }
  footer > .row.bottom > .center {
    grid-template-areas: "legal" "social" "copy";
    grid-template-columns: auto;
  }
  footer .bottom ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  footer .bottom .center > div {
    margin: 0.75em 0;
  }
}
#footer .frame {
  padding: 0;
}

footer .footer {
  line-height: 1;
}

footer .footer ul {
  list-style: none;
  margin: 0 -0.5em;
  padding: 0;
}

footer .footer li {
  display: inline;
  margin: 0 0.5em;
}

@media screen and (max-width: 575px) {
  footer .footer li {
    display: block;
    margin: 0.25rem 0;
  }
}
footer .footer a {
  text-decoration: none;
  color: inherit;
  /*border-bottom: 1px dotted transparent;*/
}

footer .footer a:hover {
  text-decoration: none;
  /*border-bottom: 1px solid #666;*/
}

@media screen and (max-width: 320px) {
  footer .footer .copy span:after {
    content: " FVA e.V.";
  }
  footer .footer .copy span + span {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  footer .legal ul {
    display: flex;
    justify-content: flex-end;
  }
}
footer .row.menus {
  background-color: #fff;
  color: #000;
  padding: 50px 0;
  line-height: 1.75;
  position: relative;
}

footer .row.menus:before,
footer .row.menus:after {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 10px;
  background-color: transparent;
  background-image: linear-gradient(90deg, #E72462 0%, var(--e-global-color-primary) 100%);
}

footer .row.menus:after {
  top: unset;
  bottom: 0;
}

footer > .menus.row > .center {
  grid-template-areas: "contact openings links";
  grid-template-columns: 5.25fr 5fr 3fr;
  align-items: start;
}

@media screen and (max-width: 992px) {
  footer > .row.menus > .center {
    grid-template-areas: "contact" "openings" "links";
    grid-auto-flow: row;
    grid-template-columns: auto;
    padding: 0 20px;
  }
  footer > .row.menus .links,
  footer > .row.menus .events,
  footer > .row.menus .infos {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  footer > .row.menus > .center {
    padding: 0 20px;
  }
}
footer > .row.menus h3 {
  color: var(--e-global-color-secondary);
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1em;
}

footer > .row.menus p {
  font-size: 1rem;
  margin-bottom: 1em;
}

footer > .row.menus ul {
  list-style: none;
}

footer > .row.menus p a,
footer > .row.menus ul li a {
  font-size: 1rem;
  text-decoration: none;
  color: inherit;
}

footer > .row.menus a:hover {
  color: #282828;
}

footer .links .frame {
  text-align: right;
}

/* app store icons */
#apps ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

#apps li {
  display: inline-block;
  padding: 0 0.01rem;
  margin: 0;
}

#apps li a {
  display: block;
  width: 10rem;
  height: 4rem;
  opacity: 1;
  transition: all 0.1s ease-out;
  overflow: hidden;
  text-indent: 12rem;
  background-repeat: no-repeat;
  background-position: center center;
}

#apps li a:hover {
  opacity: 0.9;
}

#apps li a span {
  display: none;
}

#apps a[href*=apple] {
  height: 4rem;
  width: 10rem;
  background-size: 10rem auto;
  background-image: url(../img/apple-app-store-badge-white-de.svg);
}

#apps a[href*=google] {
  height: 4rem;
  width: 11rem;
  background-size: 11rem auto;
  background-image: url(../img/google-play-store-badge-de.svg);
}

footer .row.newsletter {
  background-color: #fcfcfc;
  color: #4D4D4D;
  line-height: 1.75;
  padding: 0 20px;
}

#footer .row.newsletter .center > div {
  border-top: 1px solid #d8d8d8;
  padding: 50px 0;
}

#social {
  grid-area: social;
  display: flex;
  justify-content: center;
  /*padding: 2em 0;*/
}

#social.logolist ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

#social.logolist li {
  display: inline-block;
  padding: 0 5px;
  margin: 0 0.5rem;
}

#social.logolist li a {
  display: block;
  width: 20px;
  height: 20px;
  opacity: 0.66;
  transition: all 0.1s ease-out;
  overflow: hidden;
  text-indent: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

#social.logolist li a:hover {
  opacity: 1;
}

/* icons from here: https://simpleicons.org/ */
#social a[href*=facebook] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook icon</title><path style="fill:%23ffffff;" d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"/></svg>');
}

#social a[href*=instagram] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path style="fill:%23ffffff;" d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>');
}

#social a[href*=linkedin] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn icon</title><path style="fill:%23ffffff;" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>');
}

#social a[href*=twitter] {
  height: 20px;
  background-size: 18px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter icon</title><path style="fill:%23ffffff;"     d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/></svg>');
}

#social a[href*=youtube] {
  height: 20px;
  background-size: 20px auto;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube icon</title><path style="fill:%23ffffff;" d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"/></svg>');
}

@media screen and (max-width: 992px) {
  footer .logos li a {
    margin: 0 auto !important;
    transform: scale(1.2);
  }
}
/* ------------------------------------------------------------------------- */ /* header */
#header {
  background: #fff;
  color: #cfcfcf;
  width: 100%;
  height: 112px;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: background 0.2s ease-in-out;
  display: flex;
  flex-flow: column;
}

.sticky #header {
  background-color: #fff;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.0666666667);
  transition: background 0.02s ease-in-out;
}

#header:after {
  position: absolute;
  bottom: -10px;
  content: "";
  display: block;
  width: 100%;
  height: 10px;
  background-color: transparent;
  background-image: linear-gradient(90deg, #E72462 0%, var(--e-global-color-primary) 100%);
}

@media screen and (max-width: 992px) {
  #header {
    overflow: hidden;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  transition: padding 0.2s ease-in-out;
}

.sticky .header {
  padding: 0;
}

@media screen and (max-width: 575px) {
  .header {
    padding: 0;
  }
  #header {
    height: 90px;
  }
  #header:after {
    position: absolute;
    bottom: 0;
  }
}
/* ------------------------------------------------------------------------- */ /* responsive menu button and layer magic */
#dl-layer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  float: none;
  width: 100%;
  height: auto; /* iOS position:fixed; elements fix (not 100%) */
  transition: all 0.2s ease-out;
  /*transition-delay: 1s;*/
  --dl-color:#626262;
}

@media screen and (min-width: 992px) {
  #dl-layer {
    display: none;
  }
}
.menu-open #dl-layer {
  background-color: #fff;
  height: 80px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.0666666667);
}

.sticky.menu-open #dl-layer {
  height: 58px;
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .sticky.menu-open #dl-layer {
    height: 90px;
  }
}
@media screen and (max-width: 992px) {
  #dl-menu {
    position: absolute;
    top: 36px;
    right: 20px;
    height: 42px;
    width: 42px;
    background-color: rgba(191, 191, 191, 0.2666666667);
    border-radius: 50%;
    z-index: 23456;
    /*box-shadow: 0 0 1px rgba(44,66,12,0.75);*/
    border: 1px solid rgba(98, 98, 98, 0.0666666667);
    cursor: pointer;
    margin: 0;
    /* background: #000; */
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    color: #000;
  }
  .sticky #dl-menu {
    /*top: 5px;*/
  }
  .menu-open #dl-menu {
    background-color: rgba(191, 191, 191, 0.1333333333);
    border: 1px solid rgba(98, 98, 98, 0.2666666667);
  }
  #dl-layer button {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 14px;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    color: inherit;
    font-size: 0;
    transition: 0.35s;
    background: transparent;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: var(--dl-color);
    outline: 0;
  }
  #dl-layer button:focus {
    outline: 0;
  }
  #dl-layer button:before,
  #dl-layer button:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background: var(--dl-color);
    content: "";
    transition: transform 0.35s;
    transform: translate(-50%, -50%);
  }
  #dl-layer button.active {
    border-color: transparent;
  }
  #dl-layer button.active:before {
    transform: translate(-50%, -50%) rotate(45deg);
    background: var(--dl-color);
  }
  #dl-layer button.active:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    background: var(--dl-color);
  }
  /* menu overflow magic */
  html.menu-open {
    overflow: hidden;
  }
  body.menu-open {
    /*overflow: scroll;*/
    height: 100%;
  }
  .menu-open #nav,
  .sticky.menu-open #nav {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
  }
}
/* menu layer */
@media (max-width: 992px) {
  #dl-menu {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  header #nav {
    border: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100vw;
    padding-top: 0;
    /*background-color: rgba(29,82,135,.95);*/
    background-color: #fff;
    /*visibility: hidden;*/
    opacity: 0;
    transition: height 0.23s ease-out;
    transition-delay: 0s;
  }
  header #nav.active {
    /*visibility: visible;*/
    opacity: 1;
    height: 100%;
    transition: height 0.1s ease-in;
    /*transition-delay: .2s;*/
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  #dl-menu {
    position: absolute;
    top: 32px;
  }
  .sticky #dl-menu {
    top: 5px;
  }
}
#logo {
  position: relative;
  z-index: 123;
}

#logo > a {
  position: relative;
  top: 0;
  display: inline-block;
  background: transparent var(--logoImg) center center/100% auto no-repeat;
  border: none;
  width: 400px;
  height: 85px;
  text-indent: -1000%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  margin: 10px;
}

/*

.sticky #logo a {
	top: 8px;
	width: 100px;
	height: 50px;
	background-image: url(../img/psz-logo.png);
}
*/
@media screen and (max-width: 1410px) {
  #logo {
    padding: 0 20px;
  }
  #logo > a {
    top: 5px;
    width: 240px;
    height: 50px;
  }
  /*
  .sticky #logo a {
  	top: 2px;
  }
  */
}
/* ------------------------------------------------------------------------- */ /* main navigation: son of suckerfish dropdowns */
nav * :focus {
  outline: 0;
}

nav ul {
  list-style: none;
  padding: 0;
  border: 0;
  margin: 0;
}

nav li a {
  display: block;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5em 1.25em;
}

.sticky nav li a {
  /*color: #626262;*/
}

#menu > li:last-child > a {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.4px;
  background-color: transparent;
  background-image: linear-gradient(340deg, #E72462 0%, var(--e-global-color-primary) 100%);
  color: #fff;
  padding: 0.5rem 2rem;
  margin-left: 3rem;
  height: unset;
}

#menu > li:last-child > a:after,
#menu > li:last-child:hover > a:after {
  display: none;
}

@media (min-width: 992px) {
  header #nav {
    display: flex;
    flex-flow: column-reverse;
    position: relative;
    transition: 0.2s all ease-in-out;
  }
  nav ul {
    list-style: none;
    /* background: #f6f6f6; */
    margin-right: 1em;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    flex-flow: row;
  }
  nav li {
    padding: 0;
    /* text-align: center; */
    /*line-height: 50px;*/
  }
  nav li a {
    display: block;
    color: var(--e-global-color-primary);
    fill: var(--e-global-color-primary);
    font-weight: 700;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    word-break: keep-all;
    text-align: center;
    margin-top: 0;
    padding: 1rem;
  }
  nav li a:after {
    content: "";
    display: block;
    min-width: 70px;
    height: 3px;
  }
  nav li:hover a:after {
    background-color: var(--e-global-color-primary);
  }
  nav li ul {
    display: block;
    position: absolute;
    left: -999em;
    /* left: auto; /* debug */
    height: auto;
    width: 16em;
    font-weight: normal;
    margin: 0;
    margin-top: -0.5rem;
    padding: 0;
    background-color: var(--e-global-color-primary);
    opacity: 0;
    /* box-shadow: 0 0 1px rgba(0,0,0,0.25); */
    z-index: 23;
  }
  .no-touch nav li ul {
    transition: all 0.25s ease-in-out;
  }
  nav li li {
    margin: 0;
    padding-right: 0;
    /*width: 17em;*/
    line-height: 39px;
  }
  .sticky nav li li {
    line-height: 35px;
  }
  nav li li:last-of-type {
    border-bottom: 0;
  }
  nav li ul a {
    margin-top: 0;
    border-top: 0;
    border-bottom: 0;
    padding: 0.75em 1em;
    height: unset;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }
  nav li:hover ul a:after {
    display: none;
  }
  nav li ul a:hover {
    background-color: #fefefe;
    color: var(--e-global-color-primary);
  }
  nav li ul a span {
    position: relative;
    transition: width 0.25s ease;
  }
  nav li ul ul {
    margin: -1.25em 0 0 7.5em;
  }
  nav li:hover ul ul, nav li:hover ul ul ul {
    left: -999em;
  }
  nav li:hover ul, nav li li:hover ul, nav li li li:hover ul {
    left: auto;
    opacity: 1;
    box-shadow: 8px 12px 24px rgba(0, 0, 0, 0.2);
  }
  nav li.active {
    /*background: #fff;*/
  }
  .sticky #nav > ul > li.active > a {
    /*color: var( --e-global-color-primary );*/
  }
  .sticky #nav > ul > li.active > a:hover {
    color: var(--e-global-color-primary);
  }
  nav li.active a {
    margin-top: -1px;
    height: inherit;
  }
  nav li.active a::after {
    background-color: var(--e-global-color-primary);
    opacity: 0.25;
  }
  nav li.active li a {
    color: #fff;
    margin-top: 0;
    border-top: 0;
    height: unset;
  }
  nav li.active li.active a {
    background: #fff;
    color: #626262;
  }
  nav li a:hover {
    color: var(--e-global-color-primary);
    transition: all 0.05s ease-in-out;
    text-decoration: none;
  }
  .sticky nav li:hover li a:hover,
  nav li li a:hover {
    background-color: #fff;
    color: #626262;
    transition: all 0.1s ease-in-out;
  }
}
@media screen and (max-width: 992px) {
  nav li.login {
    border-top: 1px solid #c3c3c3;
    margin: 0 1em;
  }
  nav li.login a {
    margin-left: 1rem;
    padding: 0.5em 0.25em;
  }
  nav ul li#langswitch a {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 993px) {
  nav li.login {
    border-left: 1px solid rgba(0, 0, 0, 0.8);
    margin-left: 1.5rem;
    transition: all 0.2s ease-in-out;
  }
  .sticky nav li.login {
    border-left: 1px solid #555;
  }
  nav li.login:hover {
    background: none;
    color: inherit;
  }
  .sticky nav li.login:hover a:not(:hover) {
    color: #838383;
  }
  nav li.login a {
    margin-left: 1rem;
  }
  nav li#langswitch {
    margin-left: 1.5rem;
  }
}
#langswitch a {
  display: block;
  text-decoration: none;
  letter-spacing: 0;
  font-size: 0.8rem;
  /*
  	&:before {
  		font-family: Icons;
  		font-style: normal;
  		font-weight: 400;
  		text-decoration: inherit;
  		text-align: center;
  		content: "\f0ac";
  		padding-right: 4px;
  	}
  */
}

@media screen and (max-width: 992px) {
  nav ul {
    margin-top: 110px;
    font-size: 18px;
  }
  .sticky.menu-open nav ul {
    margin-top: 120px;
  }
  header #dl-menu #nav {
    max-width: 480px;
    background: #000;
    min-height: 100vh;
  }
  .dd #nav > ul {
    margin-left: 0;
  }
  #nav > ul > li {
    display: block;
    float: left;
    overflow: hidden;
  }
  #nav ul {
    margin-left: 0;
    display: block;
    height: unset;
    width: 100%;
    list-style: none;
  }
  .loggedin #nav ul {
    margin-top: 120px;
  }
  nav li a {
    color: #50484a;
  }
  nav li a:hover {
    color: #242626;
  }
  #nav ul li {
    float: none;
    padding: 0 0 0 0;
    text-transform: none;
    text-align: left;
    line-height: unset;
  }
  header #nav ul li:last-child {
    border-bottom: 0;
  }
  #nav ul li a {
    display: inline-block;
    margin: 4px 0;
    /* border-top: 1px solid #dcdcdc; */
    width: calc(100% - 60px);
    text-decoration: none;
    box-sizing: border-box;
  }
  #nav ul ul {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    left: auto;
    opacity: 1;
    padding: 0;
    box-shadow: unset;
    margin: 0;
  }
  #nav ul ul li {
    display: block;
  }
  #nav ul ul li a {
    padding: 4px 10px 4px 40px;
    /* border-bottom: 1px solid #efefef; */
    width: 100%;
    text-transform: unset;
    text-align: left;
    font-size: inherit;
    color: #838383;
  }
  #nav ul ul li:first-child a {
    border-top: 0;
  }
  #nav ul ul li:last-child {
    margin-bottom: 10px;
  }
  #nav ul ul ul {
    padding: 0 0 0 40px;
    max-height: unset;
  }
  #nav ul ul ul ul {
    display: none;
  }
  #nav ul li span.trigger {
    display: inline-block;
    width: 20px;
    height: 30px;
    cursor: pointer;
    background-position: center;
    position: relative;
    top: 6px;
    transition: all 0.125s ease-in-out;
    padding: 0 15px;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30.729" height="30.729" style="enable-background:new 0 0 30.729 30.729" xml:space="preserve"><path style="fill:%23626262;" d="M24.813 15.366 10.185 29.997a2.494 2.494 0 0 1-1.768.731 2.5 2.5 0 0 1-1.769-4.267l11.095-11.096L6.649 4.268A2.501 2.501 0 0 1 10.185.732l14.628 14.634z"/></svg>'); /* found here: https://www.svgrepo.com/vectors/arrow/ */
    background-repeat: no-repeat;
    background-size: 14px auto;
  }
  #nav ul li span.trigger.active {
    transform: rotate(90deg);
  }
  #nav ul li span.trigger.active + ul {
    display: block;
    margin: 0 0 10px;
    max-height: 100vh;
  }
  #nav ul li.icon-arrow-small_solid_invers,
  #nav ul li.icon-arrow-small_solid {
    background: none;
  }
}
@media screen and (max-width: 575px) {
  nav ul {
    margin-top: 110px;
  }
  .sticky.menu-open nav ul {
    margin-top: 80px;
  }
}
.menu.utilities {
  justify-content: end;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
}

.menu.utilities li.name {
  margin-right: 1rem;
  color: #888;
}

.menu.utilities li a {
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.1rem 0.125rem;
  color: #000;
}

.menu.utilities li a:after {
  height: 2px;
}

.menu.utilities li:hover a:after {
  background-color: #000;
}

.ce-table td, .ce-table th {
  vertical-align: top;
}

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext {
  overflow: hidden;
}

.ce-center .ce-outer {
  position: relative;
  float: right;
  right: 50%;
}
.ce-center .ce-inner {
  position: relative;
  float: right;
  right: -50%;
}

.ce-gallery {
  overflow: hidden;
}
.ce-gallery figure {
  display: table;
  margin: 0;
}
.ce-gallery figcaption {
  display: table-caption;
  caption-side: bottom;
}
.ce-gallery img {
  display: block;
}
.ce-gallery iframe {
  border-width: 0;
}
.ce-left .ce-gallery {
  float: left;
}
.ce-right .ce-gallery {
  float: right;
}
.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-bottom: 10px;
}
.ce-intext.ce-right .ce-gallery {
  margin-left: 10px;
}
.ce-intext.ce-left .ce-gallery {
  margin-right: 10px;
}
.ce-below .ce-gallery {
  margin-top: 10px;
}

.ce-border img, .ce-border iframe {
  border: 2px solid black;
  padding: 0;
}

.ce-column {
  float: left;
  margin-right: 10px;
}
.ce-column:last-child {
  margin-right: 0;
}

.ce-row {
  overflow: hidden;
  margin-bottom: 10px;
}
.ce-row:last-child {
  margin-bottom: 0;
}

.ce-above .ce-bodytext {
  clear: both;
}

.ce-intext.ce-left ol, .ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto;
}

.ce-headline-left {
  text-align: left;
}

.ce-headline-center {
  text-align: center;
}

.ce-headline-right {
  text-align: right;
}

.ce-uploads {
  margin: 0;
  padding: 0;
}
.ce-uploads li, .ce-uploads div {
  overflow: hidden;
}
.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}
.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top;
}
.ce-uploads span {
  display: block;
}

.frame-space-before-extra-small {
  margin-top: 1em;
}

.frame-space-before-small {
  margin-top: 2em;
}

.frame-space-before-medium {
  margin-top: 3em;
}

.frame-space-before-large {
  margin-top: 4em;
}

.frame-space-before-extra-large {
  margin-top: 5em;
}

.frame-space-after-extra-small {
  margin-bottom: 1em;
}

.frame-space-after-small {
  margin-bottom: 2em;
}

.frame-space-after-medium {
  margin-bottom: 3em;
}

.frame-space-after-large {
  margin-bottom: 4em;
}

.frame-space-after-extra-large {
  margin-bottom: 5em;
}

.frame-ruler-before:before {
  content: "";
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 2em;
}

.frame-ruler-after:after {
  content: "";
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 2em;
}

.frame-indent {
  margin-left: 15%;
  margin-right: 15%;
}

.frame-indent-left {
  margin-left: 33%;
}

.frame-indent-right {
  margin-right: 33%;
}

legend {
  font-weight: 600;
}

/* ------------------------------------------------------------------------- */ /* hide t3 translation anchors */
a[id^=c]:not([href]) {
  display: none;
}

/* ------------------------------------------------------------------------- */ /* default content */
main .frame-default {
  padding-bottom: var(--space-xl);
}

main .frame-default.frame-space-after-extra-small {
  padding-bottom: var(--space-xs);
}

main .frame-default.frame-space-after-small {
  padding-bottom: var(--space-s);
}

main .frame-default.frame-space-after-medium {
  padding-bottom: var(--space-m);
}

main .frame-default.frame-space-after-large {
  padding-bottom: var(--space-l);
}

.frame-default *:not(a) + h1,
.frame-default *:not(a) + h2,
.frame-default *:not(a) + h3,
.frame-default *:not(a) + h4,
.frame-default *:not(a) + h5 {
  padding-top: var(--space-xl);
}

.frame-default *:not(a) + p {
  padding-top: var(--space-s);
}

.frame-default .body-text a {
  color: #0563c1;
  text-decoration: none;
}
.frame-default .body-text a:hover {
  color: #053391;
}

.ce-gallery iframe {
  width: 960px;
  height: 540px;
}

.ce-gallery img {
  width: 100%;
  height: auto;
}

/* ------------------------------------------------------------------------- */
#content .bodytext ul {
  margin: 0;
  padding: 0 0 0 var(--space-m);
  list-style: none;
}

#content ul.cols {
  columns: 300px 2;
}

#content .bodytext ul li {
  margin: 0 0 0.5em;
  padding: 0 0 0 0.25em;
  position: relative;
}

#content .bodytext ul li:before {
  content: "";
  padding: 0 0.33em 0 0;
  font-size: 0.9em;
  display: block;
  width: 6px;
  height: 6px;
  background-color: #3f6bb3;
  border-radius: 2px;
  position: absolute;
  top: 0.7em;
  left: -0.7em;
}

/* ------------------------------------------------------------------------- */
a.btn.secondary {
  position: relative;
  border: 1px solid #3f6bb3;
  border-radius: 3px;
  padding: 0.8em 2em;
  background-color: #3f6bb3;
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

a.btn.secondary:hover {
  background-color: #fff;
  color: #3f6bb3;
}

/* ------------------------------------------------------------------------- */
.contenttable {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.contenttable thead th {
  padding: 1em 2em;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  text-transform: uppercase;
  min-width: 18em;
  background-color: #ececec;
}

.contenttable.benefits thead th {
  padding: 3em;
  background-color: unset;
  border-left: 0;
}

.contenttable.benefits thead th .red {
  position: absolute;
  text-transform: initial;
  font-size: 1.2em;
  transform: translate(2em, -1.5em) rotate(-23deg);
  background-color: rgba(255, 255, 255, 0.5);
}

.contenttable.benefits thead th:not(:first-child) {
  border-top: 1px solid #ddd;
}

.contenttable thead th a {
  margin-top: 0.5em;
  text-transform: initial;
}

.contenttable th:first-child,
.contenttable td:first-child {
  width: 60%;
}

.contenttable td {
  padding: 1em 2em;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.contenttable tr:nth-child(2n) {
  background-color: #f6f6f6;
}

.contenttable td .check {
  display: block;
  margin: 0 auto;
  width: 23px;
  height: 20px;
  background-size: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='20'><path fill='%23627F15' d='M3 8l-3 4 10 8L23 3l-4-3L9 13z'/></svg>");
  text-indent: 25px;
  overflow: hidden;
}

/* ------------------------------------------------------------------------- */
.ce-container.grey-bg {
  background-color: #ececec;
}

.ce-container.grey-bg .content > div {
  padding-top: var(--space-xl);
}

.ce-container.grey-bg .content > div p {
  margin-top: 1rem;
  padding-top: 1em;
}

.ce-container.orange-bg {
  background-color: #F6B958;
}

/* ------------------------------------------------------------------------- */
#content .ui.styled.accordion {
  width: 100%;
}

#content .ui.accordion .title:not(.ui) {
  font-family: inherit;
}

/* ------------------------------------------------------------------------- */
#content ul.typo3-messages {
  padding: 0;
}

#content ul.typo3-messages li {
  margin: 0;
  padding: 0;
}

#content ul.typo3-messages li:before {
  display: none;
}

#content ul.typo3-messages li p {
  padding: 0.75em 1em;
}

/* ------------------------------------------------------------------------- */
@media screen and (max-width: 575px) {
  .ce-right .ce-gallery {
    float: unset;
  }
}
/*--------------------------------------------------------------
CSS Reset
--------------------------------------------------------------*/
img {
  display: block;
  border: 0;
  width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

figure a {
  display: block;
}

.caption {
  padding-top: 0.25em;
  padding-bottom: 0.75em;
  text-align: center;
  font-style: italic;
  font-size: 0.8em;
}

/*--------------------------------------------------------------
Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar */
}

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
textarea {
  /*
  color: #404040;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  */
}

/* Promo section */
.logo,
.site-title {
  text-align: center;
}

.logo {
  font-size: 8em;
}

.site-title {
  margin-top: -1em;
  margin-bottom: 3em;
}

/* Splash section */
.splash {
  background: #FFF6E5;
  padding-bottom: 2em;
}

.grey-bg {
  background-color: lightgrey;
}

.splash-content,
.more-content,
.frame-default {
  padding: 1.5rem 0;
}

@media screen and (max-width: 1280px) {
  .splash-content,
  .more-content,
  .frame-default {
    padding: 1.5rem;
  }
}
blockquote {
  background: lightgrey;
  margin: 0;
  padding: 40px;
}

blockquote p {
  font-family: "Georgia", serif;
  font-size: 28px;
}

blockquote p, blockquote cite {
  display: block;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
}

blockquote cite {
  opacity: 0.5;
  padding-top: 1rem;
}

@media screen and (min-width: 769px) {
  .splash-text {
    columns: 2;
    column-gap: 2em;
  }
  .splash-text p {
    padding-top: 0;
  }
}
/* Buckets section */
.buckets {
  padding: 1.5rem;
  background: #3E454C;
}

.buckets ul {
  margin: 0;
  padding: 0;
}

.buckets li {
  background: white;
  list-style-type: none;
}

.bucket {
  padding: 1.5em;
}

/* More section */
.more {
  padding: 2em;
}

:root {
  --colsize: 640px;
  --cs4: calc( var(--colsize) * 0.7625);
  --cs6: calc( var(--colsize) * 1.23125);
}

/* Twins section */
.twins > *:first-child:not(.grid) {
  width: 100%;
  max-width: calc(var(--colsize) * 2 - 3rem);
  margin-left: auto;
  margin-right: auto;
}

.orange-bg .twin {
  background: #FFB94D;
  color: white;
}

.orange-bg .twin .txt {
  padding: 2rem;
}

.twins .txt {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.twins .img {
  height: 100%;
}

.twins .img figure {
  position: relative;
  height: 100%;
}

.twins .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.twins .img figure .caption {
  position: absolute;
  top: 100%;
  padding: 0.25em;
  color: initial;
}

.twins.txt-img .img figure .caption {
  right: 0;
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
  .twin + .twin .frame-default {
    padding-left: 0;
  }
}
/* Colophon section */
.colophon {
  padding: 2em;
  background: hsl(0, 0%, 10%);
  color: white;
  text-align: center;
}

.colophon a {
  color: white;
}

.colophon a:hover,
.colophon a:focus {
  border-bottom: 1px solid white;
}

/*--------------------------------------------------------------
If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/
@supports not (display: grid) {
  .grid {
    max-width: calc(var(--colsize) * 2);
    margin: 0 auto;
  }
}
/*--------------------------------------------------------------
Use flex to create a three-bucket layout
--------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  @supports (display: flex) {
    .buckets ul {
      display: flex;
      gap: 1.5rem;
    }
    .buckets li {
      width: 33%;
    }
  }
}
/*--------------------------------------------------------------
CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  /* Layout with CSS Grid */
  @supports (display: grid) {
    /* Four-column layout. Two center columns have a total max width of 50em: */
    .grid {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, var(--colsize))) 1fr;
      gap: 4rem;
    }
    .aspect-40-60 .grid {
      grid-template-columns: 1fr minmax(auto, var(--cs4)) minmax(auto, var(--cs6)) 1fr;
    }
    .aspect-60-40 .grid {
      grid-template-columns: 1fr minmax(auto, var(--cs6)) minmax(auto, var(--cs4)) 1fr;
    }
    .triplets .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .quads .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    /* Center items by placing them in the two center columns: */
    .splash-content,
    .frame-default,
    .more-content,
    .buckets ul {
      grid-column: 2/4;
    }
    /* Use automatic grid placement + span to let each item span two columns:*/
    .twin,
    .colophon aside {
      grid-column: span 2;
    }
    .twins.txt-img .txt {
      grid-column: 2;
    }
    .twins.img-txt .txt {
      grid-column: 1 span;
    }
  }
}
.align-left {
  width: 50%;
  margin-top: 0.25em;
  margin-right: 1em;
  float: left;
}

.align-right {
  margin-top: 0.25em;
  margin-left: 1em;
  float: right;
}

.align-left.half,
.align-right.half {
  width: 50%;
}

.align-left.phi,
.align-right.phi {
  width: 40%;
}

@media screen and (min-width: 1025px) {
  .align-left.half-bleed {
    width: 75%;
    margin-left: -25%;
    float: left;
  }
  .align-left.half-bleed .caption {
    text-align: right;
  }
  .align-right.half-bleed {
    width: 75%;
    margin-right: -25%;
    float: right;
  }
  .align-right.half-bleed .caption {
    text-align: left;
  }
  .align-half,
  .align-full,
  .align-full-left,
  .align-full-right,
  .align-half-left,
  .align-half-right {
    margin-left: calc((100vw - 2 * var(--colsize) + 3rem) / 2 * -1);
    width: 100vw;
    display: grid;
    grid-template-columns: [full-start] minmax(1em, 1fr) [wide-start] minmax(0, 1fr) [content-start] minmax(0, calc(2 * var(--colsize) + 3rem)) [content-end] minmax(0, 1fr) [wide-end] minmax(1em, 1fr) [full-end];
  }
  .align-half > * {
    grid-column: wide;
  }
  .align-full > * {
    grid-column: full;
  }
  .align-full-left {
    width: calc(100vw - 6rem);
  }
  .align-full-left a {
    grid-column: full-start/content-end;
  }
  .align-full-left .caption {
    grid-column: content-start/content-end;
    text-align: right;
  }
  .align-full-right {
    padding-left: 4.5rem;
    width: calc(100vw - 3rem);
  }
  .align-full-right a {
    grid-column: content-start/full-end;
    grid-row: 1;
  }
  .align-full-right .caption {
    grid-column: content-start/full-end;
    align-self: end;
    text-align: left;
    padding-right: 1em;
  }
  .align-half-left a {
    grid-column: full-start/wide-end;
  }
  .align-half-left .caption {
    grid-column: full-start/wide-end;
    grid-row: 2;
    text-align: right;
  }
  .align-half-right a {
    grid-column: wide-start/full-end;
  }
  .align-half-right .caption {
    grid-column: wide-start/full-end;
    grid-row: 2;
    text-align: left;
  }
}
@media screen and (max-width: 1024px) {
  .align-left.half-bleed {
    width: 100%;
  }
  .align-right.half-bleed {
    width: 100%;
  }
  .align-half,
  .align-full,
  .align-full-left,
  .align-full-right,
  .align-half-left,
  .align-half-right {
    margin-left: -1.5rem;
    width: 100vw;
  }
}
@media screen and (min-width: 1681px) {
  .align-full-left {
    width: calc(100vw - 6rem);
  }
  .align-full-left a {
    grid-column: full-start/content-end;
    margin-bottom: 1.25em;
  }
  .align-full-left .caption {
    grid-column: content-end/full-end;
    align-self: end;
    text-align: left;
    padding-left: 1em;
    padding-bottom: 1.25em;
  }
  .align-full-right {
    padding-left: 4.5rem;
    width: calc(100vw - 3rem);
  }
  .align-full-right a {
    grid-column: content-start/full-end;
    grid-row: 1;
    margin-bottom: 1.25em;
  }
  .align-full-right .caption {
    grid-column: full-start/content-start;
    grid-row: 1;
    align-self: end;
    text-align: right;
    padding-right: 1em;
    padding-bottom: 1.25em;
  }
}
section + hr {
  margin: 2rem 0 1rem;
}

section + hr + h3 {
  margin: 1rem 0 2rem;
}

/* ------------------------------------------------------------------------- */
.full-height {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #eee;
}

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-img.fixed {
  background-attachment: fixed;
}

.bg-img .grid > div {
  background-color: rgba(255, 255, 255, 0.5333333333);
}

/* ------------------------------------------------------------------------- */
.ce-wrapper {
  margin: 0 0 var(--space-xl);
}

@media screen and (min-width: 769px) {
  .ce-wrapper {
    display: flex;
    flex-flow: row;
    gap: 4em;
  }
  .color-bg {
    gap: 2em;
  }
}
.ce-wrapper > div {
  margin: 0;
}

.color-bg > div {
  background-color: transparent;
  background-image: linear-gradient(130deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
  border-radius: 3px;
  padding: 4em 2em;
}

.color-bg div h2 {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 42px;
  padding: 0 0 0.75rem;
}

.color-bg div h3 {
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 23px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 24px;
  padding: 2rem 0 1rem;
}

.color-bg div p {
  font-size: 17px;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .ce-wrapper.cols-2 > div {
    width: 50%;
  }
  .ce-wrapper.cols-3 > div {
    width: 33%;
  }
  .ce-wrapper.cols-4 > div {
    width: 25%;
  }
}
.frame-default h3.accordion-header {
  padding: 0;
  color: inherit;
}

.frame.frame-type-form_formframework {
  margin: 5rem 0;
}

.frame form {
  margin: 0 auto;
  width: 100%;
  max-width: 60ch;
  padding: 2rem 1.5rem;
  background: linear-gradient(130deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
  color: #fff;
}

@media screen and (max-width: 575px) {
  .frame form {
    max-width: 85vw;
  }
}
.frame form h2 {
  font-weight: normal;
  font-size: 1.5rem;
}

.frame form p {
  margin: 1rem 0;
}

.frame form fieldset {
  border: 0;
  margin: 1rem 0;
  padding-top: 0.5rem;
}

.frame form input,
.frame form select,
.frame form textarea {
  display: block;
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 90vw;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  font-family: inherit;
  font-size: 1.1rem;
  box-sizing: border-box;
}

.frame form input:not([type=radio], [type=checkbox]) {
  height: 2.5em;
}

.frame form textarea {
  height: 5.5em;
}

.frame form select {
  height: 2.5em;
}

.frame form select option {
  color: #888;
}

.frame form input[type=radio],
.frame form input[type=checkbox] {
  width: auto;
  display: inline-block;
}

.frame form input[type=submit],
.frame form button[type=submit] {
  margin: 1em auto 0;
  background-color: #E62462;
  color: #fff;
  border: 1px solid #c36;
  font-size: 1rem;
}

.frame form input[type=submit]:hover,
.frame form button[type=submit]:hover {
  color: #E62462;
  background-color: #fff;
}

[type=button]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled),
form select option {
  cursor: pointer;
}

.form-check-label span {
  padding: 0 0 0 0.5rem;
}

.form-check-label a {
  color: inherit;
}

.form-group.inline,
.inline .form-group > .input,
.inline .form-group .inputs-list .form-group {
  display: flex;
}

.inline .inputs-list .radio {
  margin-left: 3rem;
}

.inline .inputs-list .radio span {
  margin-left: 0.5rem;
}

.inline .form-group + .form-group {
  margin-left: 0.25rem;
}

#AppUnsubscribe-71-birthday {
  display: flex;
  gap: 1rem;
}

.gc-icon {
  text-align: center;
}

.gc-icon img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
}

.gc-icon .title {
  text-transform: uppercase;
}

.pageheader {
  padding: 50px 0;
  text-align: center;
}

.pageheader h2 {
  font-weight: 400;
}

.pageheader .text {
  font-weight: 300;
  font-size: var(--size-step-4);
}

#c16.ce-wrapper {
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  padding: 20px 0;
}

.psz_faq {
  width: calc(33% - 10px);
  min-height: 303px;
  background: linear-gradient(130deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
}

@media screen and (max-width: 575px) {
  .psz_faq {
    width: 100%;
  }
}
.psz_faq a {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-family: "Karla", Sans-serif;
  text-align: center;
  text-decoration: none;
}

.psz_faq .image {
  margin: 1em auto;
  display: block;
  width: 70px;
  height: 70px;
}

.psz_faq .image.icon-cross {
  background-image: url(../img/icon-cross.png);
}

.psz_faq .image.icon-heart {
  background-image: url(../img/icon-heart.png);
}

.psz_faq .image.icon-line {
  background-image: url(../img/icon-line.png);
}

.psz_faq .image.icon-people {
  background-image: url(../img/icon-people.png);
}

.psz_faq .image.icon-pump {
  background-image: url(../img/icon-pump.png);
}

.psz_faq .image.icon-question {
  background-image: url(../img/icon-question.png);
}

.psz_faq .heading {
  font-size: 23px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 24px;
}

.psz_faq .more {
  padding: 0;
}

.psz_faq .gdesc {
  display: none;
}

#u9 .ce-wrapper {
  padding: 4rem 1rem;
}

.psz_termin {
  width: calc(50% - 10px);
  min-height: 303px;
  background: linear-gradient(130deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
}

.psz_termin:hover {
  background: linear-gradient(130deg, var(--e-global-color-primary) 0%, var(--e-global-color-secondary) 100%);
}

@media screen and (max-width: 575px) {
  #u9 .psz_termin {
    width: 100%;
    min-height: auto;
    margin: 0 0 20px;
  }
}
.psz_termin a {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-family: "Karla", Sans-serif;
  text-align: center;
  text-decoration: none;
  font-size: 1.5rem;
}

.gc-header {
  position: relative;
}

.gc-header .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.gc-header .inner .heading {
  color: #E72462;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.25rem 0.75rem;
}

.gc-header .inner .alt {
  color: #fff;
  background-color: #E72462;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 600;
  max-width: 25%;
  position: absolute;
  bottom: 3rem;
  right: 0;
}

.gc-header .inner .description {
  font-size: 5rem;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  padding: 5rem;
}

@media screen and (max-width: 60rem) {
  .gc-header .inner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: block;
  }
  .gc-header .inner .heading {
    position: relative;
    bottom: unset;
  }
  .gc-header .inner .alt {
    color: #fff;
    background-color: #E72462;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 600;
    max-width: 100%;
    position: relative;
    bottom: unset;
  }
  .gc-header .inner .description {
    font-size: 3rem;
    color: #E72462;
    padding: 4rem 1rem 1rem;
    position: relative;
  }
}
.gc-123 {
  padding: 2rem 4rem;
  position: relative;
  font-size: 1.5rem;
  font-weight: 700;
  color: #E72462;
  line-height: 1.1;
  width: 33%;
}

.gc-123:nth-child(2) {
  text-align: center;
}

.gc-123 img {
  width: 100px;
  position: absolute;
  top: 1rem;
  right: 0;
}

.gc-123:nth-child(3) img {
  top: 3rem;
}

@media screen and (max-width: 60rem) {
  #c138 + div p {
    line-height: 1.5rem;
  }
  .gc-123 {
    padding: 2rem 0.5rem 4rem 0.5rem;
    width: 100%;
  }
  .gc-123:nth-child(2) {
    text-align: left;
  }
  .gc-123 img {
    top: 7rem;
    right: unset;
    left: 2rem;
  }
  .gc-123:nth-child(2) img {
    top: 5rem;
  }
  .gc-123:nth-child(3) img {
    top: 7rem;
  }
}
.gcslider .title {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
}

.splide__list {
  margin-bottom: 1rem;
}

.splide.multiple, .splide__slide {
  height: auto;
}

.splide__slide {
  padding: 1rem 1rem 2.5rem;
}

.splide.multiple img {
  border-radius: 1.5rem;
}

.gcslider .text {
  margin: 1rem 0;
  text-align: center;
}

.gc-icon {
  text-align: center;
}

.gc-icon .icon {
  padding: 1.5rem;
  width: 7.5rem;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto 1rem;
}

.gc-icon .title {
  margin: 0.25rem 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}

.content .gc-icon .txt p {
  font-size: 0.9rem;
}

@media screen and (max-width: 60rem) {
  .gc-icon {
    padding-bottom: 4rem;
  }
}
.gc-thankyou {
  padding: 3rem;
  text-align: center;
  font-size: 1.5rem;
}

/* ------------------------------------------------------------------------- */ /* News */
.frame-type-list.center {
  /*padding: 0 25px;*/
}

.frame-type-list > h2 {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  color: #552468;
}

.news-list-view {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.news-list-view:not(.videolist) {
  margin: 0 0 80px;
  flex-flow: row;
  flex-wrap: wrap;
}

@media screen and (max-width: 1320px) {
  .news-list-view {
    overflow: hidden;
  }
}
.news-list-view .entry {
  margin: 0;
  padding: 0;
  /*background-color: #fff;*/
  width: calc(33% - 0.5rem);
  position: relative;
  display: block;
}

@media screen and (max-width: 768px) {
  .news-list-view .entry {
    width: 100%;
  }
}
.news-list-view .entry:first-child {
  /*margin-top: -4px;*/
}

.news-list-view .entry a {
  color: unset;
  outline: 0;
  display: flex;
  flex-flow: column;
  text-decoration: none;
  height: 100%;
}

.news-list-view .entry h2 {
  font-size: 1.25rem;
  line-height: 1.25;
  border-bottom: 0;
  padding-bottom: 6px;
  margin-bottom: 0;
}

.frame.default .news-list-view .item h3,
.frame.default .news-list-view .entry h3 {
  font-size: 0.7em;
  font-weight: 500;
  color: #666;
  line-height: 1.25;
  padding-top: 0;
  padding-bottom: 0.25em;
  border-bottom: 0;
}

.news-list-view .entry .media-element {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.selected.news-list-view .entry .media-element {
  aspect-ratio: 2/1;
}

.news-list-view .entry .media-element img {
  width: 100%;
  height: auto;
  display: block;
  transition: 0.2s transform ease-in-out;
}

.news-list-view .entry a:hover .media-element img {
  transform: scale(1.05);
  transform-origin: center;
}

.news-list-view .entry .desc {
  padding: 0.25rem 0 1rem;
  max-width: 100%;
  display: inline-block;
  color: #fff;
}

.news-list-view:not(.videolist) .entry .desc {
  padding: 1rem;
  background: linear-gradient(130deg, var(--e-global-color-secondary) 0, var(--e-global-color-primary) 100%);
  display: flex;
  flex-flow: column;
  height: 60%;
}

.news-list-view .item .desc .date,
.news-list-view .entry .desc .date {
  font-size: 0.8em;
}

.news-list-view .item .desc .title,
.news-list-view .entry .desc .title {
  font-weight: 400;
  font-size: 1.5em;
}

.news-list-view .item .desc .subtitle,
.news-list-view .entry .desc .subtitle {
  font-weight: 400;
  font-size: 1.1em;
  padding-top: 10px;
  position: relative;
  top: -8px;
}

.news-list-view.simplelist .item {
  margin-bottom: 20px;
}

.news-list-view .more {
  display: table;
  padding: 0.5rem 0 0;
  position: relative;
}

/*

.news-list-view .more:before {
	content: "";
	position : absolute;
	top: 0;
	background-color : #F6B958;
	width: 40px;
	height: 3px;
	transition: .1s width ease-in;
}

.news-list-view .more:after {
	content: "";
	margin-left: .25em;
	display : inline-block;
	width: 1.5em;
	height: 1em;
	position : relative;
	bottom: -1px;
}


.news-list-view .entry:hover .more {
	color: #000;
}
.news-list-view .entry:hover .more:before {
	width: 100%;
}

*/
#content ul.f3-widget-paginator {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin: 4rem auto;
}

#content ul.f3-widget-paginator li {
  background: none;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0 4px;
  text-align: center;
}

#content ul.f3-widget-paginator li a {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

#content ul.f3-widget-paginator li a i {
  font-style: normal;
}

#content ul.f3-widget-paginator li.current {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
  width: 38px;
  height: 38px;
  line-height: 38px;
}

#content ul.f3-widget-paginator li.disabled {
  z-index: 2;
  color: #ccc;
  text-decoration: none;
}

#content ul.f3-widget-paginator li a:hover {
  z-index: 2;
  color: #6c757d;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

p.more {
  text-align: center;
}

p.more .link {
  display: inline-flex;
  flex-flow: column;
  margin: 0 auto 4rem;
  text-decoration: none;
  color: #000;
}

/*
p.more .link:before {
	content: "";
	margin-bottom: .5rem;
	background-color : #F6B958;
	width: 40px;
	height: 3px;
	transition: .1s width ease-in;
}
p.more:hover .link:before {
	width: 100%;
}
*/
#content .news-menu-view ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.news-menu-view > ul > li {
  margin-bottom: 1.5rem;
}

.news-menu-view > ul > li li {
  font-size: 0.825rem;
}

.news-menu-view ul li a {
  text-decoration: none;
}

.news-single .details {
  margin-bottom: 4px;
  font-size: 14px;
}

.news-single .news-list-category {
  margin-left: 4px;
  border-left: 1px solid #eee;
  padding-left: 8px;
}

.news-single .news-list-category span {
  margin-right: 6px;
}

.frame.default .news-single .title h1 {
  padding-top: 0;
}

.frame.default .news-single .title h2 {
  font-size: 1.4em;
  color: #666;
  line-height: 1.25;
  padding-top: 10px;
  border-bottom: 0;
}

.news-single .news-txt {
  margin: 25px 0;
}

.news-single .first.news-img {
  display: inline-block;
}

.news-single .first.news-img figure {
  margin: 5px 0;
  font-size: 0.8em;
}

.news-single .first.news-img img {
  width: 100%;
}

.news-single .news-img img {
  width: auto;
}

.news-single .news-related-wrap {
  margin-top: 32px;
  border-top: 1px solid #e6e6e6;
}

.news-single .news-related {
  margin-top: 32px;
}

.news-single .news-related h4 {
  font-size: 1.1em;
  text-transform: uppercase;
  color: #686868;
}

.news-single .news-related ul {
  list-style: none;
}

.news-single .info-sharing {
  display: block;
  padding: 25px 0;
  line-height: 26px;
  margin-top: 50px;
  border-top: 1px solid #e6e6e6;
  clear: both;
}

.news-single .info-sharing span {
  padding: 0 10px 0 0;
  position: relative;
  /* top: -4px; */
}

.news-single .info-sharing a {
  display: inline-block;
  margin: 0 8px;
  width: 26px;
  height: 26px;
  overflow: hidden;
  text-decoration: none;
  border-bottom: 0;
  opacity: 0.66;
  transition: all 0.5s;
  position: relative;
  top: 6px;
}

.news-single .info-sharing a:hover {
  opacity: 1;
}

.news-single .info-sharing a#li-sharer {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title id="simpleicons-linkedin-icon">LinkedIn icon</title><path fill="%23000000" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>');
}

.news-single .info-sharing a#xi-sharer {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title id="simpleicons-xing-icon">Xing icon</title><path fill="%23000000" d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z"/></svg>');
}

.content .news-menu-view ul li {
  text-indent: 0;
  margin-left: 0;
}

.news-single .news-img {
  padding-top: 25px;
  display: flex;
  position: relative;
}

.news-single .funding {
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  background-color: #fff;
  margin: 0 0 2em;
  float: right;
}

.news-single .funding img {
  height: fit-content;
}

/* ------------------------------------------------------------------------- */ /* specific content */
.cols-3.default {
  padding: 4rem 0;
}

@media screen and (max-width: 575px) {
  .cols-3.default {
    margin: 0 20px;
  }
}
.cols-3.default h3 {
  color: var(--e-global-color-secondary);
  font-family: "Poppins", Sans-serif;
  font-size: 42px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 48px;
}

.big-button {
  display: block;
  border-radius: 50%;
  background-color: transparent;
  background-image: linear-gradient(135deg, #4E1671 0%, #E72462 100%);
  color: #fff;
  text-decoration: none;
  font-family: "Poppins", Sans-serif;
  font-size: 42px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  line-height: 48px;
  padding: 31% 0;
  transform: rotate(-5deg);
}

@media screen and (max-width: 575px) {
  .cols-3.default {
    margin: 0 20px;
  }
  .big-button {
    margin: 20px 0;
  }
}
/* ------------------------------------------------------------------------- */ /* table title */
.elementor-heading-title {
  color: var(--e-global-color-secondary);
  font-family: "Poppins", Sans-serif;
  font-size: 23px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

/* ------------------------------------------------------------------------- */ /* table */
.table-responsive table {
  border-collapse: collapse;
}

.table-responsive thead {
  background-image: linear-gradient(135deg, #E72462 0%, #4E1671 100%);
  color: #fff;
}

.table-responsive thead th {
  border-right: 1px solid #fff;
  padding: 6px 10px;
  text-align: left;
  text-transform: uppercase;
}

.table-responsive td {
  padding: 10px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.table-responsive td:first-child {
  color: #e6185c;
}

.table-responsive td + td {
  border-right: 0;
}

/* ------------------------------------------------------------------------- */ /* Videos */
#content > div:not(.center).video-wrap,
#content .video-wrap {
  margin: 40px 0;
}

#content .default + .video-wrap {
  margin: 20px 0 80px;
}

.color-bg.video-wrap div h2 {
  font-size: 26px;
}

.color-bg.video-wrap .news-list-view .entry h3 {
  color: inherit;
}

.video-wrap .content {
  display: flex;
  padding-left: 2em;
}

.video-wrap .content > div:nth-child(1) {
  max-width: 25%;
  padding-right: 2rem;
}

.mediaelement-video {
  aspect-ratio: 16/9;
}

.mediaelement-video iframe {
  height: 10.5rem;
}

@media screen and (max-width: 575px) {
  .video-wrap .content {
    display: block;
  }
  .video-wrap .content > div:nth-child(1) {
    max-width: 100%;
  }
}
/* ------------------------------------------------------------------------- */ /* login */
.row.login {
  margin: 80px auto;
  width: 100%;
  max-width: 60ch;
}

.row.login h1 {
  color: #e72462;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 42px;
  padding: 0 0 0.75rem;
}

.row.login form {
  margin-top: 40px;
}

.row.login .frame form input[type=submit] {
  padding: 0;
}

/* ------------------------------------------------------------------------- */ /* app */
#app .frame {
  margin: 40px auto;
}

#app h3 {
  color: #e72462;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 42px;
  padding: 0 0 0.75rem;
}

/* ------------------------------------------------------------------------- */ /* oeffnungszeiten */
#content .frame > .frame {
  margin: 40px auto;
}

#content .frame-type-text > h3 {
  color: #e72462;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 42px;
  padding: 0 0 0.75rem;
}

/* ------------------------------------------------------------------------- */ /* ueber uns */
#u45 .frame {
  margin: 40px auto;
}

#u45 h2 {
  color: #e72462;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 42px;
  padding: 0 0 0.75rem;
}

/* ------------------------------------------------------------------------- */ /* anfahrt */
#c19 .ce-bodytext p {
  text-align: center;
}

/* ------------------------------------------------------------------------- */ /* femanager */
.tx-femanager {
  margin: 4rem auto;
  width: 100%;
  max-width: 60ch;
  padding: 2rem 1.5rem;
}

.femanager_new .femanager_email.form-group {
  /*display : none;*/
}

.profile {
  margin: 2rem auto 6rem;
}

.femanager_show ul {
  margin: 0 0 2rem 1rem;
}

.tx-femanager .controls {
  display: flex;
  justify-content: end;
}

.tx-femanager .btn {
  background-color: #E62462;
  color: #fff;
  border: 0;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.66rem;
}

.tx-femanager .btn:hover {
  color: #E62462;
  background-color: #fff;
}

/* ------------------------------------------------------------------------- */ /* streetview */
#c217 iframe {
  width: 100%;
  min-height: 60vh;
  overflow: hidden;
  margin: 3rem 0;
}

/* ------------------------------------------------------------------------- */ /* streetview */
.login-notice {
  padding: 4rem 0;
  text-align: center;
}

body.type777 {
  min-height: unset;
  display: unset;
  flex-flow: unset;
  background: linear-gradient(130deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
}

.type777 #container {
  height: 100vh;
  display: block;
}

.type777 #content {
  padding: 0;
  background: none;
}

.type777 #content .center {
  margin: 0;
  padding: 0;
  width: unset;
  max-width: unset;
}

.type777 .femanager_new {
  margin: 0;
  padding: 0;
}

.type777 .frame form {
  width: unset;
  max-width: unset;
  background: none;
}

.type777 .frame form a {
  color: inherit;
}

.type777 .frame form fieldset {
  margin: 0;
  padding: 0;
}

.type777 .frame form input[type=submit] {
  margin: 1rem 0 0;
  padding: 0;
}

.type777 .frame form #did-dob {
  margin-top: 0;
  padding-top: 0;
}

.type777 .frame form p {
  font-size: 0.8rem;
  margin: 0.25rem 0;
}

.frame form .checkbox {
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.frame form div.checkbox {
  margin-bottom: 1rem;
  line-height: 0.9rem;
}

.frame form div.checkbox input {
  margin-bottom: 0;
}

.type777 .row.login {
  margin: 0;
  max-width: 100%;
}

.type777 .row.login h1,
.type777 .row.login h1 + p,
.type777 .frame-type-felogin_login + * {
  display: none;
}

.type777 .row.login form {
  margin: 0;
}

.type777 .row.login form + p {
  display: none;
}

.type777 .tx-femanager {
  margin: 0;
  padding: 0;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  background: #ccc;
  border: 0;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0.7;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 2em;
  width: 2em;
}

.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.splide__arrow:disabled {
  opacity: 0.3;
}

.splide__arrow:focus-visible {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 8px;
  opacity: 0.7;
}

.splide__pagination__page.is-active {
  background: #fff;
  z-index: 1;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}

.splide__pagination__page:focus-visible {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__pagination__page:focus {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: transparent;
}

.splide__slide:focus {
  outline: 0;
}

.splide__slide:focus-visible {
  /* IE does not support outline-offset */
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline: #0bf 3px solid;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: #0bf 3px solid;
  }
}
.splide.is-focus-in .splide__slide:focus {
  /* IE does not support outline-offset */
}

@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline: #0bf 3px solid;
    outline-offset: -3px;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: #0bf 3px solid;
  }
}
@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}
.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide.is-focus-in .splide__toggle:focus {
  outline: #0bf 3px solid;
  outline-offset: 3px;
}

.splide__track--nav > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%, 0);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotateZ(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  bottom: 1em;
  top: auto;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotateZ(90deg);
}

.splide__pagination--ttb {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

.splide {
  height: 800px;
  overflow: hidden;
}

.splide__slide {
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
}

.splide__slide .inner {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.splide__slide .img {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
}

.splide__slide .inner .heading {
  color: var(--e-global-color-secondary);
  font-family: "Karla", Sans-serif;
  font-size: 62px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 62px;
  margin-bottom: 1rem;
}

.splide__slide .inner .description {
  display: inline;
  padding: 3px 10px;
  background-image: linear-gradient(90deg, #E72462 0%, var(--e-global-color-primary) 100%);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: oblique;
  line-height: 38px;
}

.splide__pagination__page.is-active {
  background-color: var(--e-global-color-secondary);
}

.splide__arrow {
  width: 3em;
  height: 3em;
  background-color: var(--e-global-color-secondary);
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--next {
  right: 1em;
}

.splide__arrow svg {
  fill: #fff;
}

@media screen and (max-width: 575px) {
  .splide__arrow {
    top: 95%;
  }
  .splide__pagination {
    bottom: 3.5%;
  }
  .splide {
    height: 600px;
  }
  .splide__slide .img {
    position: relative;
  }
  .splide__slide .inner {
    bottom: unset;
    left: 1em;
    padding-top: 1em;
  }
  .splide__slide .inner .heading {
    padding-top: 0.5em;
    font-size: 32px;
    line-height: 32px;
  }
  .splide__slide .inner .description {
    font-size: 20px;
    line-height: 36px;
  }
}
.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe,
.gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img,
.desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  /* max-width: 160vmin; */
  margin: auto;
}

.gslide-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media,
.desc-bottom .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom,
.gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.1;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
  opacity: 0 !important;
}

/*Skin */
.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

/*CSS Animations*/
.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image,
  .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left,
  .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner,
  .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gprev,
  .glightbox-clean .gnext,
  .glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gprev:hover,
  .glightbox-clean .gnext:hover,
  .glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
.glightbox-container .gslide-inner-content {
  max-width: 900px;
  padding: 20px;
  background-image: linear-gradient(100deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
}

.gslide-description.description-left {
  max-width: 500px;
}

.glightbox-clean .description-left .gdesc-inner {
  box-sizing: border-box;
}

.glightbox-clean .gslide-media {
  box-shadow: unset;
}

.glightbox-clean .gslide-title {
  color: #E72462;
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.2;
}

.glightbox-clean .gslide-desc {
  color: #000000;
  font-family: "Karla", Sans-serif;
  font-size: 16px;
  line-height: 22px;
}

/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/*
Variables that begin with $var- should NEVER be used directly in CSS rules.
they should only be included via the "@include var(property, var-name)"
mechanism.
*/
/* Border styles */
/* Cookie notice positioning */
/* Text colors */
/* Font Families */
/* White */
/* Differently shaded colors */
/*
Use @include var(property, varname) to include a variable, e.g.

    @include var(background-color, white1);

to create a white background.
*/
/* Spacing */
/* Breakpoints */
.klaro {
  font-family: inherit;
  font-family: var(--font-family, inherit);
  font-size: 14px;
  font-size: var(--font-size, 14px);
}

.klaro button {
  font-family: inherit;
  font-family: var(--font-family, inherit);
  font-size: 14px;
  font-size: var(--font-size, 14px);
}

.klaro.cm-as-context-notice {
  height: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
}

.klaro .cookie-modal,
.klaro .context-notice,
.klaro .cookie-notice {
  /* The switch - the box around the slider */
}

.klaro .cookie-modal .cm-switch-container,
.klaro .context-notice .cm-switch-container,
.klaro .cookie-notice .cm-switch-container {
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-color: #c8c8c8;
  border-bottom-color: var(--light2, #c8c8c8);
  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;
}

.klaro .cookie-modal .cm-switch-container:last-child,
.klaro .context-notice .cm-switch-container:last-child,
.klaro .cookie-notice .cm-switch-container:last-child {
  border-bottom: 0;
}

.klaro .cookie-modal .cm-switch-container:first-child,
.klaro .context-notice .cm-switch-container:first-child,
.klaro .cookie-notice .cm-switch-container:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch-container p,
.klaro .context-notice .cm-switch-container p,
.klaro .cookie-notice .cm-switch-container p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch,
.klaro .context-notice .cm-switch,
.klaro .cookie-notice .cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
  opacity: 0.6;
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
  cursor: not-allowed;
}

.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal .cm-list-input,
.klaro .context-notice .cm-list-input,
.klaro .cookie-notice .cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-title,
.klaro .context-notice .cm-list-title,
.klaro .cookie-notice .cm-list-title {
  font-size: 0.9em;
  font-weight: 600;
}

.klaro .cookie-modal .cm-list-description,
.klaro .context-notice .cm-list-description,
.klaro .cookie-notice .cm-list-description {
  color: #7c7c7c;
  color: var(--dark3, #7c7c7c);
  font-size: 0.9em;
  padding-top: 4px;
}

.klaro .cookie-modal .cm-list-label,
.klaro .context-notice .cm-list-label,
.klaro .cookie-notice .cm-list-label {
  /* The slider */
  /* Rounded sliders */
}

.klaro .cookie-modal .cm-list-label .cm-switch,
.klaro .context-notice .cm-list-label .cm-switch,
.klaro .cookie-notice .cm-list-label .cm-switch {
  position: absolute;
  left: 0;
}

.klaro .cookie-modal .cm-list-label .slider,
.klaro .context-notice .cm-list-label .slider,
.klaro .cookie-notice .cm-list-label .slider {
  background-color: #f2f2f2;
  background-color: var(--white2, #f2f2f2);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  width: 50px;
  display: inline-block;
}

.klaro .cookie-modal .cm-list-label .slider::before,
.klaro .context-notice .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-label .slider::before {
  background-color: #e6e6e6;
  background-color: var(--white3, #e6e6e6);
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  transition: 0.4s;
}

.klaro .cookie-modal .cm-list-label .slider.round,
.klaro .context-notice .cm-list-label .slider.round,
.klaro .cookie-notice .cm-list-label .slider.round {
  border-radius: 30px;
}

.klaro .cookie-modal .cm-list-label .slider.round::before,
.klaro .context-notice .cm-list-label .slider.round::before,
.klaro .cookie-notice .cm-list-label .slider.round::before {
  border-radius: 50%;
}

.klaro .cookie-modal .cm-list-label input:focus + .slider,
.klaro .context-notice .cm-list-label input:focus + .slider,
.klaro .cookie-notice .cm-list-label input:focus + .slider {
  box-shadow-color: #48dfb2;
  box-shadow-color: var(--green3, #48dfb2);
  box-shadow: 0 0 1px var(color, green3);
}

.klaro .cookie-modal .cm-list-label input:checked + .slider::before,
.klaro .context-notice .cm-list-label input:checked + .slider::before,
.klaro .cookie-notice .cm-list-label input:checked + .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:focus + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
  box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #1a936f;
  color: var(--green1, #1a936f);
  text-decoration: none;
}

.klaro .cookie-modal p,
.klaro .cookie-modal strong,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice strong,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice strong,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  color: #fafafa;
  color: var(--light1, #fafafa);
}

.klaro .cookie-modal p,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 0.7em;
}

.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal h3,
.klaro .cookie-modal h4,
.klaro .cookie-modal h5,
.klaro .cookie-modal h6,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice h3,
.klaro .context-notice h4,
.klaro .context-notice h5,
.klaro .context-notice h6,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice h3,
.klaro .cookie-notice h4,
.klaro .cookie-notice h5,
.klaro .cookie-notice h6 {
  font-family: inherit;
  font-family: var(--title-font-family, inherit);
}

.klaro .cookie-modal .cm-link,
.klaro .context-notice .cm-link,
.klaro .cookie-notice .cm-link {
  margin-right: 0.5em;
  vertical-align: middle;
}

.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  color: #fff;
  color: var(--button-text-color, #fff);
  background-color: #5c5c5c;
  background-color: var(--blue1, #5c5c5c);
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  margin-right: 0.5em;
  border-style: none;
  padding: 0.4em 2.4em;
  font-size: 1em;
  cursor: pointer;
}

.klaro .cookie-modal .cm-btn:disabled,
.klaro .context-notice .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
  opacity: 0.5;
}

.klaro .cookie-modal .cm-btn.cm-btn-close,
.klaro .context-notice .cm-btn.cm-btn-close,
.klaro .cookie-notice .cm-btn.cm-btn-close {
  background-color: #c8c8c8;
  background-color: var(--light2, #c8c8c8);
}

.klaro .cookie-modal .cm-btn.cm-btn-success,
.klaro .context-notice .cm-btn.cm-btn-success,
.klaro .cookie-notice .cm-btn.cm-btn-success {
  background-color: #1a936f;
  background-color: var(--blue1, #1a936f);
}

.klaro .cookie-modal .cm-btn.cm-btn-success-var,
.klaro .context-notice .cm-btn.cm-btn-success-var,
.klaro .cookie-notice .cm-btn.cm-btn-success-var {
  background-color: #24cc9a;
  background-color: var(--blue1, #24cc9a);
}

.klaro .cookie-modal .cm-btn.cm-btn-info,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-info {
  background-color: #2581c4;
  background-color: var(--blue1, #2581c4);
}

.klaro .context-notice {
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  border-style: solid;
  border-style: var(--border-style, solid);
  border-width: 1px;
  border-width: var(--border-width, 1px);
  border-color: #c8c8c8;
  border-color: var(--light2, #c8c8c8);
  background-color: #fafafa;
  background-color: var(--light1, #fafafa);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
}

.klaro .context-notice.cm-dark {
  background-color: #333;
  background-color: var(--dark1, #333);
  border-color: #5c5c5c;
  border-color: var(--dark2, #5c5c5c);
}

.klaro .context-notice.cm-dark p {
  color: #fafafa;
  color: var(--light1, #fafafa);
}

.klaro .context-notice.cm-dark p a {
  color: #459cdc;
  color: var(--blue2, #459cdc);
}

.klaro .context-notice p {
  color: #333;
  color: var(--dark1, #333);
  flex-grow: 0;
  text-align: center;
  padding-top: 0;
  margin-top: 0;
}

.klaro .context-notice p a {
  color: #24cc9a;
  color: var(--green2, #24cc9a);
}

.klaro .context-notice p.cm-buttons {
  margin-top: 12px;
}

.klaro .cookie-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1000;
}

.klaro .cookie-modal.cm-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  z-index: 0;
}

.klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
  position: relative;
  -ms-transform: none;
  transform: none;
}

.klaro .cookie-modal .cm-bg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: #fafafa;
  color: var(--light1, #fafafa);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
}

@media (min-width: 660px) {
  .klaro .cookie-modal .cm-modal.cm-klaro {
    border-radius: 4px;
    position: relative;
    margin: 0 auto;
    max-width: 640px;
    height: auto;
    width: auto;
  }
}
.klaro .cookie-modal .cm-modal .hide {
  border-style: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.klaro .cookie-modal .cm-modal .hide svg {
  stroke: #fafafa;
  stroke: var(--light1, #fafafa);
}

.klaro .cookie-modal .cm-modal .cm-footer {
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-footer-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .klaro .cookie-modal .cm-modal .cm-footer-buttons {
    flex-flow: column;
    gap: 0.75em;
  }
}
.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
  font-size: 0.8em;
  padding-top: 4px;
  text-align: right;
  padding-right: 8px;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
}

.klaro .cookie-modal .cm-modal .cm-header {
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-color: #5c5c5c;
  border-bottom-color: var(--dark2, #5c5c5c);
  padding: 1em;
  padding-right: 24px;
}

.klaro .cookie-modal .cm-modal .cm-header h1 {
  margin: 0;
  font-size: 2em;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-header h1.title {
  padding-right: 20px;
}

.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul {
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body span {
  display: inline-block;
  width: auto;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
  padding: 0;
  margin: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
  margin-left: -40px;
  display: none;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
  margin-top: 10px;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
  position: relative;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 60px;
  min-height: 40px;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
  font-size: 0.8em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  padding-top: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
  font-weight: 600;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
  padding-left: 0.2em;
  font-size: 0.8em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: #333;
  background-color: var(--dark1, #333);
  z-index: 999;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-radius: 4px;
    position: fixed;
    position: var(--notice-position, fixed);
    right: 20px;
    right: var(--notice-right, 20px);
    left: auto;
    left: var(--notice-left, auto);
    bottom: 20px;
    bottom: var(--notice-bottom, 20px);
    top: auto;
    top: var(--notice-top, auto);
    max-width: 400px;
    max-width: var(--notice-max-width, 400px);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  }
}
@media (max-width: 1023px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-style: none;
    border-radius: 0;
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
  z-index: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
  padding-top: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  margin-bottom: 0;
  margin-right: 0;
  bottom: 0;
  padding: 1em;
  padding-top: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
  margin-bottom: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
  text-decoration: underline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
  display: inline-block;
  flex-grow: 1;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  display: inline-block;
  margin-top: -0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
    width: 100%;
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  margin-top: 0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    width: calc(50% - 0.5em);
  }
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  margin-top: -0.5em;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: right;
  align-items: baseline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
  margin-top: 0.5em;
}

.klaro .cookie-modal-notice {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: #fafafa;
  color: var(--light1, #fafafa);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  padding: 1em;
  padding-top: 0.2em;
}

@media (min-width: 400px) {
  .klaro .cookie-modal-notice {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    height: auto;
    width: auto;
  }
}
.klaro .cookie-modal-notice .cn-ok {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.klaro .cookie-notice-hidden {
  display: none !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  letter-spacing: var(--tracking);
}

h1, .h1 {
  font-size: var(--size-step-6);
}

h2, .h2 {
  font-size: var(--size-step-5);
}

h3, .h3 {
  font-size: var(--size-step-4);
}

h4, .h4 {
  font-size: var(--size-step-3);
}

h5, .h5 {
  font-size: var(--size-step-2);
}

h6, .h6 {
  font-size: var(--size-step-1);
}

small, .text_small {
  font-size: var(--size-step-0);
}

@media screen and (max-width: 768px) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    font-weight: 600;
  }
}
.btn {
  display: inline-block;
  border: 1px solid black;
  padding: 0.1rem;
}

h2 .btn-primary {
  font-size: var(--size-step-1);
}

.btn-primary {
  background-color: #FFB94D;
  color: #fff;
  border: 1px solid #F6B958;
  border-radius: 2px;
  padding: 0.5em 2.5em;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.025);
  text-decoration: none;
  transition: all 0.05s ease-in-out;
}

.btn-primary:hover {
  background-color: #fff;
  color: #FFB94D;
}

.btn-secondary {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 0.5em 2.5em 0.75em;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.025);
  text-decoration: none;
  transition: all 0.05s ease-in-out;
  height: 42px;
  line-height: 42px;
}

.btn-secondary:hover {
  background-color: #fff;
  color: #000;
}

.btn-more {
  margin: 2rem 0;
  color: inherit;
  border: 0;
  text-decoration: none;
  transition: all 0.05s ease-in-out;
  position: relative;
}

.btn-more:after {
  content: "";
  width: 50%;
  height: 2px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FFB94D;
  transition: all 0.05s ease-in-out;
}

.orange-bg .btn-more:after {
  background-color: #fff;
}

.btn-more:hover:after {
  width: 100%;
}

.red {
  color: #f00;
}

.red {
  color: #f00;
}

strong {
  color: #e72462;
  text-transform: uppercase;
}

.color-bg strong {
  color: #fff;
}