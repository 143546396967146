body.type777 {
	min-height: unset;
	display : unset;
	flex-flow: unset;
	background: linear-gradient(130deg, var(--e-global-color-secondary) 0%, var(--e-global-color-primary) 100%);
}


.type777 #container {
	height: 100vh;
	display: block;
}

.type777 #content {
	padding: 0;
	background : none;
}

.type777 #content .center {
	margin: 0;
	padding: 0;
	width: unset;
	max-width: unset;
}

.type777 .femanager_new {
	margin: 0;
	padding: 0;
}

.type777 .frame form {
	width: unset;
	max-width: unset;
	background: none;
}

.type777 .frame form a {
	color: inherit;
}

.type777 .frame form fieldset {
	margin: 0;
	padding: 0;
}

.type777 .frame form input[type="submit"] {
	margin: 1rem 0 0;
	padding: 0;
}

.type777 .frame form #did-dob {
	margin-top: 0;
	padding-top: 0;
}
.type777 .frame form p {
	font-size : .8rem;
	margin: .25rem 0;
}

.frame form .checkbox {
	font-size: .8rem;
	line-height: .9rem;
}
.frame form div.checkbox {
	margin-bottom: 1rem;
	line-height: .9rem;
}
.frame form div.checkbox input {
	margin-bottom : 0;
}



.type777 .row.login {
	margin: 0;
	max-width: 100%;
}

.type777 .row.login h1,
.type777 .row.login h1 + p,
.type777 .frame-type-felogin_login + * {
	display : none;
}

.type777 .row.login form {
	margin: 0;
}
.type777 .row.login form + p {
	display : none;
}


.type777 .tx-femanager {
	margin: 0;
	padding: 0;
}

