/* ------------------------------------------------------------------------- */ /* specific content */

.cols-3.default {
	padding: 4rem 0;
}

@media screen and (max-width: $sm-break-point) {
	.cols-3.default {
		margin: 0 20px;
	}
}


.cols-3.default h3 {
	color: var( --e-global-color-secondary );
	font-family: "Poppins", Sans-serif;
	font-size: 42px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 48px;
}

.big-button {
	display : block;
	border-radius: 50%;
	background-color: transparent;
	background-image: linear-gradient(135deg, #4E1671 0%, #E72462 100%);
	color: #fff;
	text-decoration : none;
	font-family: "Poppins", Sans-serif;
	font-size: 42px;
	font-weight: 500;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	line-height: 48px;
	padding: 31% 0;
	transform: rotate(-5deg);
}

@media screen and (max-width: $sm-break-point) {
	.cols-3.default {
		margin: 0 20px;
	}
	.big-button {
		margin: 20px 0;
	}
}


/* ------------------------------------------------------------------------- */ /* table title */

.elementor-heading-title {
	color: var( --e-global-color-secondary );
	font-family: "Poppins", Sans-serif;
	font-size: 23px;
	font-weight: 600;
	text-transform: uppercase;
	margin-top: 1.5em;
	margin-bottom: 1em;
}

/* ------------------------------------------------------------------------- */ /* table */

.table-responsive table {
	border-collapse: collapse;
}
.table-responsive thead {
	background-image: linear-gradient(135deg, #E72462 0%, #4E1671 100%);
	color: #fff;
}
.table-responsive thead th {
	border-right: 1px solid #fff;
	padding: 6px 10px;
	text-align : left;
	text-transform : uppercase;
}
.table-responsive td {
	padding: 10px;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
}

.table-responsive td:first-child {
	color: #e6185c;
}

.table-responsive td + td {
	border-right: 0;
}

/* ------------------------------------------------------------------------- */ /* Videos */




#content > div:not(.center).video-wrap,
#content .video-wrap {
	margin: 40px 0;
}

#content .default + .video-wrap {
	margin: 20px 0 80px;
}

.color-bg.video-wrap div h2 {
	font-size : 26px;
}

.color-bg.video-wrap .news-list-view .entry h3 {
	color: inherit;
}

.video-wrap .content {
	display : flex;
	padding-left: 2em;

}

.video-wrap .content > div:nth-child(1) {
	max-width: 25%;
	padding-right: 2rem;
}

.video-wrap .content > div:nth-child(2) {
}

.mediaelement-video {
	aspect-ratio: 16/9;
}

.mediaelement-video iframe {
	height: 10.5rem;
}

@media screen and (max-width: $sm-break-point) {
	.video-wrap .content {
		display : block;
	}
	.video-wrap .content > div:nth-child(1) {
		max-width: 100%;
	}
}



/* ------------------------------------------------------------------------- */ /* login */

.row.login {
	margin: 80px auto;
	width: 100%;
	max-width: 60ch;
}

.row.login h1 {
	color: #e72462;
	font-family: "Poppins", Sans-serif;
	font-size: 36px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 42px;
	padding: 0 0 0.75rem;

}


.row.login form {
	margin-top: 40px;
}

.row.login .frame form input[type="submit"] {
	padding: 0;
}



/* ------------------------------------------------------------------------- */ /* app */

#app .frame {
	margin: 40px auto;
}

#app h3 {
	color: #e72462;
	font-family: "Poppins", Sans-serif;
	font-size: 36px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 42px;
	padding: 0 0 0.75rem;
}


/* ------------------------------------------------------------------------- */ /* oeffnungszeiten */

#content .frame > .frame {
	margin: 40px auto;
}

#content .frame-type-text > h3 {
	color: #e72462;
	font-family: "Poppins", Sans-serif;
	font-size: 36px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 42px;
	padding: 0 0 0.75rem;

}

/* ------------------------------------------------------------------------- */ /* ueber uns */

#u45 .frame {
	margin: 40px auto;
}

#u45 h2 {
	color: #e72462;
	font-family: "Poppins", Sans-serif;
	font-size: 36px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 42px;
	padding: 0 0 0.75rem;
}


/* ------------------------------------------------------------------------- */ /* anfahrt */

#c19 .ce-bodytext p {
	text-align : center;
}



/* ------------------------------------------------------------------------- */ /* femanager */

.tx-femanager {
	margin: 4rem auto;
	width: 100%;
	max-width: 60ch;
	padding: 2rem 1.5rem;
}


.femanager_new .femanager_email.form-group {
	/*display : none;*/
}



.profile {
	margin: 2rem auto 6rem;
}


.femanager_show ul {
	margin: 0 0 2rem 1rem;
}

.femanager_show li {

}

.femanager_status {
}

.tx-femanager .controls {
	display : flex;
	justify-content: end;
}
.tx-femanager .btn {
	background-color: #E62462;
	color: #fff;
	border: 0;
	padding: 0.5rem 1.5rem;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	line-height: 1.66rem;
}
.tx-femanager .btn:hover {
	color: #E62462;
	background-color: #fff;
}


/* ------------------------------------------------------------------------- */ /* streetview */

#c217 iframe {
	width: 100%;
	min-height: 60vh;
	overflow: hidden;
	margin: 3rem 0;
}


/* ------------------------------------------------------------------------- */ /* streetview */

.login-notice {
	padding: 4rem 0;
	text-align : center;
}
