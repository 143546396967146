#logo {
	position: relative;
	z-index: 123;
}

#logo > a {
	position: relative;
	top: 0;
	display: inline-block;
	background: transparent var(--logoImg) center center / 100% auto no-repeat;
	border: none;
	width: 400px;
	height: 85px;
	text-indent: -1000%;
	overflow: hidden;
	transition: all .2s ease-in-out;
    margin: 10px;
}
/*

.sticky #logo a {
	top: 8px;
	width: 100px;
	height: 50px;
	background-image: url(../img/psz-logo.png);
}
*/

@media screen and (max-width: $xxl-break-point) {
	#logo {
		padding: 0 20px;
	}
	#logo > a {
		top: 5px;
		width: 240px;
		height: 50px;
	}
	/*
	.sticky #logo a {
		top: 2px;
	}
	*/
}
