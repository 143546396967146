// styles for RTE classes

.red {
    color: #f00;
}



strong {
	color: #e72462;
	text-transform : uppercase;
}
.color-bg strong {
	color: #fff;
}
