/*
 default FSC styles
 these styles need to be adjusted when
 Configuration/TypoScript/Constants/Plugin/0.fsc.constants.typoscript
 is adjusted
 */
$ce-borderWidth: 2px; // styles.content.textmedia.borderWidth
$ce-borderColor: black; // styles.content.textmedia.borderColor
$ce-borderPadding: 0; // styles.content.textmedia.borderPadding
$ce-textMargin: 10px; // styles.content.textmedia.textMargin
$ce-columnSpacing: 10px; // styles.content.textmedia.columnSpacing
$ce-rowSpacing: 10px; // styles.content.textmedia.rowSpacing
//
//
// /* BS default breakpoints */
// /*
// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px,
//     xxl: 1400px
// );
//
// $container-max-widths: (
//     sm: 540px,
//     md: 720px,
//     lg: 960px,
//     xl: 1140px,
//     xxl: 1320px
// );
// */
//
// /*
//  best-practice breakpoints
//  */
// $xs-break-point: 320px;
// $sm-break-point: 575px;
// $md-break-point: 768px;
// $lg-break-point: 992px;
// $xl-break-point: 1280px;
// $xxl-break-point: 1440px;
// $hd-break-point: 1920px;
//
//
//
// $c-white: #FFFFFF;
// $c-light-gray: #EDEDED;
// $c-gray: #B2B2B2;
// $c-black: #000000;
//
$psz-blue: #3f6bb3;
$psz-gray: #242626;
$psz-gray-light: #50484a;
$psz-gray-lighter: #60585a;
$psz-orange:  #f6b958;
//
// // colors:
// $primary: $psz-blue;
// $yellow: rgb(204,136,18);
//
// $body-bg: $c-white;
// $body-color: $c-black;
// $link-color: $psz-blue;
// $link-hover-color: $psz-gray;
// $link-decoration: none;
// $link-hover-decoration: underline;
//
// $headings-color: $c-black;
//
// $h1-font-size: 4rem;
// $h2-font-size: 2rem;
// $h3-font-size: 1.5rem;
// $h4-font-size: 1.3rem;
//
// $grid-gutter-width: 1rem;
// $spacer: 2rem;
//
// $footer-space: calc($spacer / 2);
//
// $navbar-padding-y: $footer-space;
// $navbar-nav-link-padding-x: $footer-space;
// $nav-link-font-weight: 500;
// $navbar-dark-color: rgba($c-white, 1);
// $navbar-dark-hover-color: rgba($c-white, .8);
//
// $btn-border-radius: 1.2rem;
//
// $btn-padding-x: 2.25rem;
// $btn-padding-y: .25rem;
//
