footer .row.menus {
	background-color: #fff;
	color: #000;
	padding: 50px 0;
	line-height: 1.75;
	position : relative;
}
footer .row.menus:before,
footer .row.menus:after {
	position : absolute;
	top: 0;
	content: "";
	display : block;
	width: 100%;
	height: 10px;
	background-color: transparent;
	background-image: linear-gradient(90deg, #E72462 0%, var( --e-global-color-primary ) 100%);
}
footer .row.menus:after {
	top: unset;
	bottom: 0;
}

footer > .menus.row > .center {
	grid-template-areas:
			"contact openings links"
;
	grid-template-columns: 5.25fr 5fr 3fr;
	align-items: start;
}
@media screen and (max-width: $lg-break-point) {
	footer > .row.menus > .center {
		grid-template-areas:
			"contact"
			"openings"
			"links"
		;
		grid-auto-flow: row;
		grid-template-columns: auto;
		padding: 0 20px;
	}
	footer > .row.menus .links,
	footer > .row.menus .events,
	footer > .row.menus .infos {
		display : none;
	}
}
@media screen and (max-width: $xl-break-point) {
	footer > .row.menus > .center {
		padding: 0 20px;
	}
}


footer > .row.menus h3 {
	color: var( --e-global-color-secondary );
	font-family: "Poppins", Sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 1em;
}

footer > .row.menus p {
	font-size: 1rem;
	margin-bottom: 1em;
}

footer > .row.menus ul {
	list-style: none;
}
footer > .row.menus p a,
footer > .row.menus ul li a {
	font-size: 1rem;
	text-decoration : none;
	color: inherit
}
footer > .row.menus a:hover {
	color: #282828;
}


footer .links .frame {
	text-align : right;

}


/* app store icons */

#apps ul {
	display: flex;
	list-style: none;
	align-items: center;
	justify-content: end;
	gap: .5rem;
	margin: 0;
	padding: 0;
}
#apps li {
	display: inline-block;
	padding: 0 .01rem;
	margin: 0;
}
#apps li a {
	display: block;
	width:  10rem;
	height:  4rem;
	opacity: 1;
	transition: all 0.1s ease-out;
	overflow: hidden;
	text-indent: 12rem;
	background-repeat: no-repeat;
	background-position: center center;
}
#apps li a:hover {
	opacity: 0.9;
}
#apps li a span {
	display : none;
}

#apps a[href*="apple"]  { height: 4rem; width: 10rem; background-size: 10rem auto; background-image: url(../img/apple-app-store-badge-white-de.svg); }
#apps a[href*="google"] { height: 4rem; width: 11rem; background-size: 11rem auto; background-image: url(../img/google-play-store-badge-de.svg); }
