.frame.frame-type-form_formframework {
	margin: 5rem 0;

}

.frame form {
	margin: 0 auto;
	width: 100%;
	max-width: 60ch;
	padding: 2rem 1.5rem;
	background: linear-gradient(130deg, var( --e-global-color-secondary ) 0%, var( --e-global-color-primary ) 100%);
	color: #fff;
}
@media screen and (max-width: $sm-break-point) {
	.frame form {
		max-width: 85vw;
	}
}


.frame form h2 {
	font-weight : normal;
	font-size : 1.5rem;
}

.frame form p {
	margin: 1rem 0;
}

.frame form fieldset {
	border: 0;
	margin: 1rem 0;
	padding-top: .5rem;
}

.frame form input,
.frame form select,
.frame form textarea {
	display        : block;
	margin-bottom     : .75rem;
	width          : 100%;
	max-width      : 90vw;
	background     : transparent;
	color          : #fff;
	border         : 1px solid #fff;
	border-radius  : 2px;
	padding: .25rem .5rem;
	font-family    : inherit;
	font-size : 1.1rem;
	box-sizing: border-box;
}

.frame form input:not([type="radio"], [type="checkbox"]) {
	height: 2.5em;
}
.frame form textarea {
	height: 5.5em;
}

.frame form select {
	height: 2.5em;
}
.frame form select option {
	color: #888;
}

.frame form input[type=radio],
.frame form input[type=checkbox] {
	width: auto;
	display : inline-block;
}


.frame form input[type="submit"],
.frame form button[type="submit"] {
	margin: 1em auto 0;
	background-color: #E62462;
	color: #fff;
	border: 1px solid #c36;
	font-size : 1rem;
}
.frame form input[type="submit"]:hover,
.frame form button[type="submit"]:hover {
	color: #E62462;
	background-color: #fff;
}
[type="button"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled),
form select option {
	cursor: pointer;
}

.form-check-label span {
	padding: 0 0 0 .5rem;
}
.form-check-label a {
	color: inherit;
}


.form-group.inline ,
.inline .form-group > .input,
.inline .form-group .inputs-list .form-group {
	display : flex;
}

.inline .inputs-list .radio {
	margin-left: 3rem;
}
.inline .inputs-list .radio span {
	margin-left: .5rem;
}


.inline .form-group + .form-group {
	margin-left: .25rem
}




#AppUnsubscribe-71-birthday {
	display: flex;
	gap: 1rem;
}
