@import "../../../../../../../node_modules/glightbox/dist/css/glightbox";


.glightbox-container .gslide-inner-content {
	max-width: 900px;
	padding: 20px;
	background-image: linear-gradient(100deg, var( --e-global-color-secondary ) 0%, var( --e-global-color-primary ) 100%);
	box-shadow: 2px 8px 23px 3px rgba(0,0,0,0.2);
}

.gslide-description.description-left {
	max-width: 500px;
}

.glightbox-clean .description-left .gdesc-inner {
	box-sizing: border-box;
}

.glightbox-clean .gslide-media {
	box-shadow: unset;
}

.glightbox-clean .gslide-title {
	color: #E72462;
	font-family: "Poppins", Sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 2rem;
	line-height : 1.2;
}

.glightbox-clean .gslide-desc {
	color: #000000;
	font-family: "Karla", Sans-serif;
	font-size: 16px;
	line-height: 22px;
}
