/* ------------------------------------------------------------------------- */ /* main navigation: son of suckerfish dropdowns */

nav * :focus { outline: 0; }

nav ul {
	list-style: none;
	padding: 0;
	border: 0;
	margin: 0;
}
nav li a {
	display: block;
	font-weight: 500;
	text-decoration: none;
	padding: 0.5em 1.25em;
}
.sticky nav li a {
	/*color: #626262;*/
}

#menu > li:last-child > a {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 1.4px;
	background-color: transparent;
	background-image: linear-gradient(340deg, #E72462 0%, var( --e-global-color-primary ) 100%);
	color: #fff;
	padding: .5rem 2rem;
	margin-left: 3rem;
	height: unset;
}
#menu > li:last-child > a:after,
#menu > li:last-child:hover > a:after {
	display : none;
}


@media (min-width: $lg-break-point) {
	header #nav {
		display : flex;
		flex-flow: column-reverse;
		position : relative;
		transition: .2s all ease-in-out;
	}
	.sticky header #nav {
	}

	nav ul {
		list-style: none;
		/* background: #f6f6f6; */
		margin-right: 1em;
		padding: 0;
		border: 0;
		display: flex;
		align-items: center;
		flex-flow: row;
	}

	nav li {
		padding: 0;
		/* text-align: center; */
		/*line-height: 50px;*/
	}


	nav li a {
		display: block;
		color: var( --e-global-color-primary );
		fill: var( --e-global-color-primary );
		font-weight: 700;
		text-decoration: none;
		font-family : "Poppins", sans-serif;
		font-size: 1rem;
		font-weight: 700;
		word-break: keep-all;
		text-align: center;
		margin-top: 0;
		padding: 1rem;
	}
	nav li a:after {
		content: "";
		display : block;
		min-width: 70px;
		height: 3px;
	}
	nav li:hover a:after {
		background-color : var( --e-global-color-primary );
	}

	nav li ul {
		display: block;
		position: absolute;
		left: -999em;
		/* left: auto; /* debug */
		height: auto;
		width: 16em;
		font-weight: normal;
		margin: 0;
		margin-top: -.5rem;
		padding: 0;
		background-color: var(--e-global-color-primary);
		opacity: 0;
		/* box-shadow: 0 0 1px rgba(0,0,0,0.25); */
		z-index : 23;
	}
	.no-touch  nav li ul {
		transition: all .25s ease-in-out;
	}

	nav li li {
		margin: 0;
		padding-right: 0;
		/*width: 17em;*/
		line-height: 39px;
	}
	.sticky nav li li {
		line-height: 35px;
	}

	nav li li:last-of-type {
		border-bottom: 0;
	}

	nav li ul a {
		margin-top: 0;
		border-top: 0;
		border-bottom: 0;
		padding: .75em 1em;
		height: unset;
		color: #fff;
		font-weight: 400;
		line-height: 1.5;
		text-align: left;
	}
	nav li:hover ul a:after {
		display : none;
	}

	nav li ul a:hover {
		background-color: #fefefe;
		color: var(--e-global-color-primary);
	}
	nav li ul a span {
		position : relative;
		transition: width .25s ease;
	}
	nav li ul ul {
		margin: -1.25em 0 0 7.5em;
	}

	nav li:hover ul ul, nav li:hover ul ul ul {
		left: -999em;
	}

	nav li:hover ul, nav li li:hover ul, nav li li li:hover ul {
		left: auto;
		opacity: 1;
		box-shadow: 8px 12px 24px rgba(0,0,0,0.2);
	}

	nav li:hover {
	}
	nav li:hover a,
	.sticky nav li:hover a {
	}
	nav li.active {
		/*background: #fff;*/
	}
	.sticky #nav > ul > li.active > a {
		/*color: var( --e-global-color-primary );*/
	}
	.sticky #nav > ul > li.active > a:hover {
		color: var( --e-global-color-primary );
	}
	nav li.active a {
		margin-top: -1px;
		height: inherit;
	}
	nav li.active a::after {
		background-color: var(--e-global-color-primary);
		opacity: .25;
	}

	nav li.active li a {
		color: #fff;
		margin-top: 0;
		border-top: 0;
		height: unset;
	}
	nav li.active li.active a {
		background: #fff;
		color: #626262;
	}

	nav li a:hover {
		color: var( --e-global-color-primary );
		transition: all .05s ease-in-out;
		text-decoration : none;

	}

	.sticky nav li:hover li a:hover,
	nav li li a:hover {
		background-color: #fff;
		color: #626262;
		transition: all .1s ease-in-out;
	}
}


@media screen and (max-width: $lg-break-point) {
	nav li.login {
		border-top: 1px solid #c3c3c3;
		margin: 0 1em;
	}
	nav li.login a {
		margin-left: 1rem;
		padding: 0.5em 0.25em;
	}
	nav ul li#langswitch a {
		width: 100%;
		text-align : center;
	}
}
@media screen and (min-width: $lg-break-point + 1) {
	nav li.login {
		border-left: 1px solid #000c;
		margin-left: 1.5rem;
		transition: all .2s ease-in-out;
	}
	.sticky nav li.login {
		border-left: 1px solid #555;
	}
	nav li.login:hover {
		background: none;
		color: inherit;
	}
	.sticky nav li.login:hover a:not(:hover) {
		color: #838383;
	}
	nav li.login a {
		margin-left: 1rem;
	}
	nav li#langswitch {
		margin-left: 1.5rem
	}
}


#langswitch a {
	display: block;
	text-decoration: none;
	letter-spacing: 0;
	font-size: 0.8rem;
	/*
		&:before {
			font-family: Icons;
			font-style: normal;
			font-weight: 400;
			text-decoration: inherit;
			text-align: center;
			content: "\f0ac";
			padding-right: 4px;
		}
	*/
}

@media screen and (max-width: $lg-break-point) {
	nav ul {
		margin-top: 110px;
		font-size: 18px;
	}
	.sticky.menu-open nav ul {
		margin-top : 120px;
	}

	header #dl-menu #nav {
		max-width: 480px;
		background: #000;
		min-height: 100vh;
	}

	.dd #nav > ul {
		margin-left: 0;
	}
	#nav > ul > li {
		display: block;
		float: left;
		overflow: hidden;
	}
	#nav ul {
		margin-left: 0;
		display: block;
		height: unset;
		width: 100%;
		list-style: none;
	}
	.loggedin #nav ul{
		margin-top: 120px;
	}
	nav li a {
		color: $psz-gray-light;
	}
	nav li a:hover {
		color: $psz-gray;
	}
	#nav ul li {
		float: none;
		padding: 0 0 0 0;
		text-transform: none;
		text-align: left;
		line-height: unset;
	}
	header #nav ul li:last-child {
		border-bottom: 0;
	}

	#nav ul li a {
		display: inline-block;
		margin: 4px 0;
		/* border-top: 1px solid #dcdcdc; */
		width: calc( 100% - 60px );
		text-decoration: none;
		box-sizing: border-box;
	}

	#nav ul ul {
		position: relative;
		max-height: 0;
		overflow: hidden;
		transition: all .5s ease-in-out;
		left: auto;
		opacity: 1;
		padding: 0;
		box-shadow: unset;
		margin: 0;
	}

	#nav ul ul li {
		display: block;
	}

	#nav ul ul li a {
		padding: 4px 10px 4px 40px;
		/* border-bottom: 1px solid #efefef; */
		width: 100%;
		text-transform: unset;
		text-align: left;
		font-size: inherit;
		color: #838383;
	}
	#nav ul ul li:first-child a {
		border-top: 0;
	}
	#nav ul ul li:last-child {
		margin-bottom: 10px;
	}

	#nav ul ul ul {
		padding: 0 0 0 40px;
		max-height: unset;
	}
	#nav ul ul ul a {
	}
	#nav ul ul ul ul {
		display: none;
	}

	#nav ul li span.trigger {
		display: inline-block;
		width: 20px;
		height: 30px;
		cursor: pointer;
		background-position: center;
		position: relative;
		top: 6px;
		transition: all .125s ease-in-out;
		padding: 0 15px;
		background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30.729" height="30.729" style="enable-background:new 0 0 30.729 30.729" xml:space="preserve"><path style="fill:%23626262;" d="M24.813 15.366 10.185 29.997a2.494 2.494 0 0 1-1.768.731 2.5 2.5 0 0 1-1.769-4.267l11.095-11.096L6.649 4.268A2.501 2.501 0 0 1 10.185.732l14.628 14.634z"/></svg>'); /* found here: https://www.svgrepo.com/vectors/arrow/ */
		background-repeat: no-repeat;
		background-size: 14px auto;
	}
	#nav ul li span.trigger.active {
		transform: rotate(90deg);
	}

	#nav ul li span.trigger.active + ul {
		display: block;
		margin: 0 0 10px;
		max-height: 100vh;
	}

	#nav ul li.icon-arrow-small_solid_invers,
	#nav ul li.icon-arrow-small_solid {
		background: none;
	}
}
@media screen and (max-width: $sm-break-point) {
	nav ul {
		margin-top : 110px;
	}
	.sticky.menu-open nav ul {
		margin-top : 80px;
	}
}

nav li:hover #a9 ~ ul.dl-submenu {

}




.menu.utilities {
	justify-content: end;
	margin-right: 1rem;
	margin-bottom: .25rem;
}

.menu.utilities li.name {
	margin-right: 1rem;
	color: #888;
}

.menu.utilities li a {
	font-weight: 500;
	font-size : .9rem;
	padding: .1rem .125rem;
	color: #000;
}

.menu.utilities li a:after {
	height: 2px;
}
.menu.utilities li:hover a:after {
	background-color : #000;
}
