section+hr  {
	margin: 2rem 0 1rem;
}
section+hr+h3  {
	margin: 1rem 0 2rem;
}

/* ------------------------------------------------------------------------- */

.full-height {
	height: 100vh;
	display : grid;
	place-items: center;
	background-color : #eee;
}

.bg-img {
	background-position: center center;
	background-size: cover;
	background-repeat : no-repeat;
}

.bg-img.fixed {
	background-attachment : fixed;
}

.bg-img .grid > div {
	background-color : #fff8;
}


/* ------------------------------------------------------------------------- */


.ce-wrapper {
	margin: 0 0 var(--space-xl);
}

@media screen and (min-width: $md-break-point + 1) {
	.ce-wrapper {
		display : flex;
		flex-flow: row;
		gap: 4em;
	}
	.color-bg {
		gap: 2em;
	}
}

.ce-wrapper > div {
	margin: 0;
}

.color-bg > div {
	background-color: transparent;
	background-image: linear-gradient(130deg, var( --e-global-color-secondary ) 0%, var( --e-global-color-primary ) 100%);	border-radius: 3px;
	padding: 4em 2em;
}

.color-bg div h2 {
	color: #FFFFFF;
	font-family: "Poppins", Sans-serif;
	font-size: 40px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 42px;
	padding: 0 0 .75rem;
}

.color-bg div h3 {
	color: #FFFFFF;
	font-family: "Poppins", Sans-serif;
	font-size: 23px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 24px;
	padding: 2rem 0 1rem;
}

.color-bg div p {
	font-size : 17px;
	color: #fff;
}



@media screen and (min-width: $md-break-point + 1) {
	.ce-wrapper.cols-2 > div {
		width: 50%;
	}
	.ce-wrapper.cols-3 > div {
		width: 33%;
	}
	.ce-wrapper.cols-4 > div {
		width: 25%;
	}
}



.frame-default h3.accordion-header {
	padding: 0;
	color: inherit;
}
