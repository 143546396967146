/* ------------------------------------------------------------------------- *\

	css layout formatting for Content First, static footer, sections, responsive! · template
	written by the left hand of mtness

	2022-02-15

*/
/* ------------------------------------------------------------------------- */ /* global layout */

* { margin: 0; padding: 0; } img, div { border: 0; }

html {
	overflow-x: hidden; /* due to .start.deco.h1 */
	/* overflow-y: hidden; /* TODO find fix for overlapping fixed header */
	scroll-behavior: smooth;
	font-size : 100%;
}

body {
	min-height:100%;
	font-family: var(--font-base);
	font-size: var(--size-step-0);
	line-height: 1.75;
	background: #fff;
	color: #1E1B1B;
	opacity: 1;
	transition: 1s opacity;
	background: #fff;
	display : flex;
	flex-flow: column;
}
body.fadein {
	opacity: 0;
	transition: none;
}



/* ------------------------------------------------------------------------- */

.center {
	position: relative;
	width: 100%;
	max-width: $xxl-break-point;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
}
@media screen and (max-width: $sm-break-point) {
	#content > .center {
		padding: 0 20px;
	}
}


/* ------------------------------------------------------------------------- */ /* sections & content */

#content {
	background:#fcfcfc;
	color: #4D4D4D;
	padding: 122px 0 0;
}
@media screen and (max-width: $sm-break-point) {
	#content {
		padding-top: 90px;
	}
}


#content > div:not(.center) {
	margin: 0 -20px;
}
@media screen and (max-width: $sm-break-point) {
	#content > div:not(.center) {
		margin: 0;
	}
}



#content > .section:first-of-type {
	padding: 42px 0;
}
@media screen and (max-width: $md-break-point) {
	#content > .section:first-of-type {
		padding: 25px 0 50px 0;
	}
}

.section.first h1 {
	font-size: 3rem;
	font-weight: 500;
	color: $psz-blue;
	text-align: center;
	padding-bottom: 50px;
	text-transform: uppercase;
}

.section.first h2 {
	font-size: 2rem;
	font-weight: 300;
	text-align: center;
	color: #232323;
	padding-bottom: 50px;
}
@media screen and (max-width: $md-break-point) {
	.section.first h2 {
		font-size: 1.1em;
	}
}

.section.first p {
	line-height: 1.75;
	padding-bottom: .5em;
	max-width: 960px;
	margin: 0 auto;
}

.section.first ul {
	margin: 0 0 1em 1em;
}

.section.first ul li {
	padding-bottom: .5em;
}

/* ------------------------------------------------------------------------- */

.section.grey-bg {
	padding: 50px 0;
	background-color: #F2F2F2;
	text-align: center;
}

/* ------------------------------------------------------------------------- */

.section.cta {
	padding: 150px 0 50px;
	text-align: center;
	background-color: #fff;
}

.cta .frame-default a {
	position: relative;
	border: 1px solid $psz-blue;
	border-radius: .5em;
	padding: .8em 3em;
	background-color: $psz-blue;
	text-decoration: none;
	color: #fff;
	font-size: 1em;
	font-weight: 600;
	transition: all .2s ease-in-out;
}
/*
.cta .frame-default a:after {
	content: "";
	margin: 0 0 0 8px;
	display: inline-block;
	width: 12px;
	height: 12px;
	background: ;
}
*/


.cta .frame-default a:hover {
	background-color: #fff;
	color: $psz-blue;
}
/*
.cta .frame-default a:after:hover {
	color: #F57321;
}
*/

@media screen and (max-width: $md-break-point) {
	.cta .frame-default a {
		font-size: 1em;
		padding: .5em 1em;
	}
}


/* ------------------------------------------------------------------------- */ /* atf content styling */

#atf {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	height: max( 200px, 38vh - 100px );
	text-align: center;
	color: #fff;
}
.pagets__index #atf {
	height: calc(80vh - 100px);
}

#atf {
	h1,.h1,h2,.h2,h3,.h3,h4,.h4 {
		padding-bottom: 1em;
		color: #fff;
	}
}

@media screen and (max-width: $md-break-point) {
	.pagets__index #atf {
		/*height: unset;*/
	}
	#atf h1 {
		font-size: 24px;
		line-height: 1.33;
		padding-top: .5em;
		padding-bottom: 0;
	}

}
@media screen and (min-width: 960px) {
	.pagets__index #atf {
		height: 100%;
	}
	#atf h1 {
		padding-top: .75em;
		padding-bottom: .25em;
		font-weight: 600;
	}
	#atf h1 br {
		display: none;
	}
}
@media screen and (min-width: 960px) and (max-width: 1366px) {
	#atf {
		transform: scale(0.8);
	}
}
@media screen and (min-width: 1680px) {
	#atf h1 {
		padding-top: .25em;
		padding-bottom: .25em;
	}
}

#atf .timer {
	line-height: 48px;
	padding-bottom: 24px;
}

#atf .atf-nav {
	display: flex;
}
@media screen and (max-width: $md-break-point) {
	#atf .timer {
		padding-bottom: 0;
	}
	#atf .atf-nav {
		flex-flow: column-reverse;
	}
}

#atf .atf-nav a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: 500;
	text-decoration: none;
	padding: .5rem 1rem;
	width: 100%;
	height: 100%;
}
#atf .atf-nav > div {
	margin: 1rem;
	width: 28vw;
	max-width: 18rem;
	font-size: 1.33rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all .2s ease-in-out;
	box-sizing: border-box;
	border-radius: 2rem;
	/* border: 1px solid #ffffff22; */
}

@media screen and (max-width: $md-break-point) {
	#atf .atf-nav > div {
		width: 80vw;
	}
}


#atf .atf-nav .blue {
	background-color: $psz-blue;
}
#atf .atf-nav .red {
	background-color: #e0011a;
}
#atf .atf-nav > div:hover {
	background-color: #fff;
	color: #013B4F;
	/* border: 1px solid #ffffff33; */
}
#atf .atf-nav .blue a:hover { color:$psz-blue; }
#atf .atf-nav .red a:hover { color:#e0011a; }

@media screen and (max-width: 1680px) {
	#atf .atf-nav > div {
		margin:.5em 1em;
	}
}
@media screen and (min-width: 1681px) {

}

#atf h3 {
	max-width: 50vw;
	font-size: 42px;
	font-weight: normal;
	line-height: 48px;
	padding-top: 2em;
	display: none;
}
.pagets__index #atf h3 {
	display: block;
}
#atf h3 strong {
	font-weight: normal;
}
@media screen and (max-width: $md-break-point) {
	#atf h3 {
		display: none;
	}
}
@media screen and (min-width: 769px) and (max-width: 1680px) {
	#atf h3 {
		padding-top: .5em;
		max-width: 80vw;
	}
}
@media screen and (min-width: 1681px) {
	#atf h3 {
		padding-top: 0;
	}
}

/* ------------------------------------------------------------------------- */

.tm-yt h3 {
	font-weight: 600;
	font-size: 4em;
}

.tm-yt h4 {
	font-size: 2.5em;
}

.tm-yt h5 {
	padding-top: 1em;
	font-size: 1.75em;
	font-weight: 600;
}

@media screen and (max-width: $md-break-point) {
	.tm-yt h3 {
		font-size: 1.5em;
	}

	.tm-yt h4 {
		font-size: 1.25em;
	}

	.tm-yt .ce-gallery iframe {
		width: 100%;
		height: auto;
	}

	.tm-yt h5 {
		font-size: 1em;
	}
}



/* ------------------------------------------------------------------------- */ /* timer */

#timer .frame.default {
	margin-bottom: 0;
}

.timer.j-timer-first {
	display: flex;
	justify-content: center;
	font-size: 4rem;
	font-weight: 300;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;
	color: #fff;
}

header #date .timer span {
	line-height: 1;
}

.timer b {
	font-weight: 100;
	line-height: 1;
	display: flex;
	justify-content: center;
	flex-flow: column;
	padding: 16px 48px;
	position: relative;

}

.timer i {
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
}

.timer b:not(:last-child)::before {
	content: ":";
	position: absolute;
	left: 85%;
	top: 40%;
	font-size: 3rem;
	transform: translate(15px,-50%);
}


@media (max-width: 1024px) {
	.timer.j-timer-first {
		font-size: 2em;
		margin-top: 0;
	}
	.timer b {
		padding-left: 16px;
		padding-right: 16px;
	}
	.timer i {
		font-size: 11px;
	}
	.timer b:not(:last-child)::before {
		font-size: 2rem;
	}
}

/* ------------------------------------------------------------------------- */ /* page register */

.frame-default  .register h1 {
	font-size: 2em;
	text-align: left;
	padding: 2em 0;
}
.register fieldset > legend {
	padding: 1em 0;
}

/* ------------------------------------------------------------------------- */ /* newsletter */

.frame-default  .tx-fp-newsletter h1 {
	font-size: 2em;
	text-align: left;
	padding: 2em 0 0;
}

.tx-fp-newsletter .ui.checkbox label {
	font-size: 0.75em;
	line-height: 1.33;
	max-width: 80%;
}


/* ------------------------------------------------------------------------- */ /* bootstrap adjustments */

#auth-error {
	text-align : center;
	background-color : #fff;
	color: #E62462;
	padding: .5rem;
}

.login label {
	font-size: 0.8rem;
	display: inline-block;
	margin-bottom: 0.25rem;
}

.login input[type="button"].btn-block, input[type="reset"].btn-block, input[type="submit"].btn-block {
	width: 100%;
	padding: .5rem 3rem;
}

.login .forgot-pass {
	display : inline-block;
	margin-top: 1rem;
	font-size : 0.8rem;
	color: #fff;
	text-decoration : none;
}
.login .forgot-pass:hover {
	text-decoration : underline;
}


/* ------------------------------------------------------------------------- */
