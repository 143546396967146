/* ------------------------------------------------------------------------- */ /* footer */

footer {
	font-family : "Poppins", sans-serif;
	font-size: 1.1rem;
	min-height: 90px;
	overflow : hidden;
}
footer > .row {
	display: flex;
	padding: 25px 20px;
}
footer > .row > .center {
	max-width: $xxl-break-point;
	margin: 0 auto;
	padding: 0;
	display: grid;
	align-items: center;
}
footer > .menus.row > .center {
	/*padding: 0 25px;*/
}

footer h5 {
	font-size: var(--size-step-0);
	font-weight: normal;
	color: #4D4D4D;
}




footer .contact {
	grid-area: contact;
}
footer .openings {
	grid-area: openings;
}
footer .links {
	grid-area: links;
}

footer .copy {
	grid-area: copy;
}

footer .social {
	grid-area: social;
}

footer .legal {
	grid-area: legal;
}







@media screen and (max-width: $xl-break-point + 40) {
	footer > .row > .center {
		max-width : 1240px;
	}
}





footer .logos ul {
	display: flex;
	justify-content: space-around;
	list-style: none;
}
footer .logos h2 { /* position: absolute; bottom: 0; right: 342px; width: 200px; */ color: #BBBDBF; font-weight: normal; font-size: 10px; padding: 0 0 15px 0; text-align: center; }
footer .logos li { display: inline-block; padding: 0; }
footer .logos li a  { display: block; position: relative; overflow: hidden; height: 0; }

@media screen and (max-width: $md-break-point) {
	footer .logos ul {
		flex-flow: column;
	}
	footer .logos li {
		margin: 1em 0;
	}
}



footer > .row.bottom > .center {
	display: grid;
	grid-template-areas:
            "copy social legal"
	;
	grid-template-columns: 2fr 1fr 2fr;
	align-items: center;
	justify-content: space-around;
}

@media screen and (min-width: $sm-break-point + 1 ) and (max-width: $lg-break-point) {
	footer > .row.bottom > .center {
		grid-template-areas:
            "social social"
            "copy legal"
		;
		grid-template-rows: auto;
		grid-template-columns: auto;
		row-gap: 25px;
		justify-content: space-between;
	}
}
@media screen and (max-width: $sm-break-point) {
	footer > .row {
		padding: 1em 0;
		text-align : center;
	}

	footer > .row.bottom > .center {
		grid-template-areas:
            "legal"
            "social"
            "copy"
	;
		grid-template-columns: auto;
	}

	footer .bottom ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	footer .bottom .center > div {
		margin: .75em 0;
	}
}



#footer .frame {
	padding: 0;
}

footer .footer {
	line-height: 1;
}
footer .footer ul {
	list-style: none;
	margin: 0 -.5em;
	padding: 0;
}
footer .footer li {
	display: inline;
	margin: 0 .5em;
}
@media screen and (max-width: $sm-break-point) {
	footer .footer li {
		display: block;
		margin: 0.25rem 0;
	}

}


footer .footer a {
	text-decoration: none;
	color: inherit;
	/*border-bottom: 1px dotted transparent;*/
}
footer .footer a:hover {
	text-decoration: none;
	/*border-bottom: 1px solid #666;*/
}



