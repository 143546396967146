
.ce-table {
	td, th {
		vertical-align: top;
	}
}

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext {
	overflow: hidden;
}

.ce-center {
	.ce-outer {
		position: relative;
		float: right;
		right: 50%;
	}
	.ce-inner {
		position: relative;
		float: right;
		right: -50%;
	}
}

.ce-gallery {
	overflow: hidden;

	figure {
		display: table;
		margin: 0;
	}
	figcaption {
		display: table-caption;
		caption-side: bottom;
	}
	img {
		display: block;
	}
	iframe {
		border-width: 0;
	}

	.ce-left & {
		float: left;
	}
	.ce-right & {
		float: right;
	}
	.ce-intext.ce-right &, .ce-intext.ce-left &, .ce-above & {
		margin-bottom: $ce-textMargin;
	}
	.ce-intext.ce-right & {
		margin-left: $ce-textMargin;
	}
	.ce-intext.ce-left & {
		margin-right: $ce-textMargin;
	}
	.ce-below & {
		margin-top: $ce-textMargin;
	}
}

.ce-border {
	img, iframe {
		border: $ce-borderWidth solid $ce-borderColor;
		padding: $ce-borderPadding;
	}
}

.ce-column {
	float: left;
	margin-right: $ce-columnSpacing;
	&:last-child {
		margin-right: 0;
	}
}

.ce-row {
	overflow: hidden;
	margin-bottom: $ce-rowSpacing;
	&:last-child {
		margin-bottom: 0;
	}
}

.ce-above .ce-bodytext {
	clear: both;
}

.ce-intext.ce-left {
	ol, ul {
		padding-left: 40px;
		overflow: auto;
	}
}

.ce-headline-left {
	text-align: left;
}

.ce-headline-center {
	text-align: center;
}

.ce-headline-right {
	text-align: right;
}

.ce-uploads {
	margin: 0;
	padding: 0;

	li, div {
		overflow: hidden;
	}
	li {
		list-style: none outside none;
		margin: 1em 0;
	}
	img {
		float: left;
		padding-right: 1em;
		vertical-align: top;
	}
	span {
		display: block;
	}
}

.frame-space-before-extra-small {
	margin-top: 1em;
}

.frame-space-before-small {
	margin-top: 2em;
}

.frame-space-before-medium {
	margin-top: 3em;
}

.frame-space-before-large {
	margin-top: 4em;
}

.frame-space-before-extra-large {
	margin-top: 5em;
}

.frame-space-after-extra-small {
	margin-bottom: 1em;
}

.frame-space-after-small {
	margin-bottom: 2em;
}

.frame-space-after-medium {
	margin-bottom: 3em;
}

.frame-space-after-large {
	margin-bottom: 4em;
}

.frame-space-after-extra-large {
	margin-bottom: 5em;
}

.frame-ruler-before:before {
	content: '';
	display: block;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
	margin-bottom: 2em;
}

.frame-ruler-after:after {
	content: '';
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	margin-top: 2em;
}

.frame-indent {
	margin-left: 15%;
	margin-right: 15%;
}

.frame-indent-left {
	margin-left: 33%;
}

.frame-indent-right {
	margin-right: 33%;
}
