
// styles for mask elements
// @import "content/<elementname>";

// @import "content/pageheader";


// font-sizes found in rte.scss

legend {
    font-weight: 600;
}



/* ------------------------------------------------------------------------- */ /* hide t3 translation anchors */

a[id^="c"]:not([href]) {
    display: none;
}

/* ------------------------------------------------------------------------- */ /* default content */

main .frame-default {
    padding-bottom: var(--space-xl);
}
main .frame-default.frame-space-after-extra-small {
    padding-bottom: var(--space-xs);
}
main .frame-default.frame-space-after-small {
    padding-bottom: var(--space-s);
}
main .frame-default.frame-space-after-medium {
    padding-bottom: var(--space-m);
}
main .frame-default.frame-space-after-large {
    padding-bottom: var(--space-l);
}

.frame-default *:not(a) + h1,
.frame-default *:not(a) + h2,
.frame-default *:not(a) + h3,
.frame-default *:not(a) + h4,
.frame-default *:not(a) + h5 {
    padding-top: var(--space-xl);
}
.frame-default *:not(a) + p {
    padding-top: var(--space-s);
}


.frame-default .body-text a {
    color: #0563c1;
    text-decoration: none;

    &:hover {
        color: #053391;
    }
}

.ce-gallery iframe {
    width: 960px;
    height: 540px;
}

.ce-gallery img {
    width: 100%;
    height: auto;
}

/* ------------------------------------------------------------------------- */

#content .bodytext ul {
    margin: 0;
    padding: 0 0 0 var(--space-m);
    list-style: none;
}
#content ul.cols {
    columns: 300px 2;
}
#content .bodytext ul li {
    margin: 0 0 .5em;
    padding: 0 0 0 .25em;
    position : relative;
}
#content .bodytext ul li:before {
    content: "";
    padding: 0 .33em 0 0;
    font-size: 0.9em;
    display: block;
    width: 6px;
    height: 6px;
    background-color: $psz-blue;
    border-radius: 2px;
    position: absolute;
    top: .7em;
    left: -.7em;
}

/* ------------------------------------------------------------------------- */

a.btn.secondary {
    position: relative;
    border: 1px solid $psz-blue;
    border-radius: 3px;
    padding: .8em 2em;
    background-color: $psz-blue;
    text-decoration: none;
    color: #fff;
    font-size: 1.2em;
    font-weight: 600;
    transition: all .2s ease-in-out;
}

a.btn.secondary:hover {
    background-color: #fff;
    color: $psz-blue;
}

/* ------------------------------------------------------------------------- */

.contenttable {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.contenttable thead th {
    padding: 1em 2em;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    text-transform : uppercase;
    min-width: 18em;
    background-color: #ececec;
}
.contenttable.benefits thead th {
    padding: 3em;
    background-color: unset;
    border-left: 0;
}

.contenttable.benefits thead th .red {
    position : absolute;
    text-transform : initial;
    font-size : 1.2em;
    transform: translate(2em, -1.5em) rotate(-23deg);
    background-color : rgba(255,255,255,0.5);
}


.contenttable.benefits thead th:not(:first-child) {
    border-top: 1px solid #ddd;
}

.contenttable thead th a {
    margin-top: .5em;
    text-transform: initial;
}


.contenttable th:first-child,
.contenttable td:first-child {
    width: 60%;
}

.contenttable td {
    padding: 1em 2em;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.contenttable tr:nth-child(2n) {
    background-color : #f6f6f6;
}

.contenttable td .check {
    display: block;
    margin: 0 auto;
    width: 23px;
    height: 20px;
    background-size: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='23' height='20'><path fill='%23627F15' d='M3 8l-3 4 10 8L23 3l-4-3L9 13z'/></svg>");
    text-indent : 25px;
    overflow : hidden;
}

/* ------------------------------------------------------------------------- */

.ce-container.grey-bg {
    background-color : #ececec;
}
.ce-container.grey-bg .content > div {
    padding-top: var(--space-xl);
}
.ce-container.grey-bg .content > div p {
    margin-top: 1rem;
    padding-top: 1em;
}

.ce-container.orange-bg {
    background-color : #F6B958;
}

/* ------------------------------------------------------------------------- */

#content .ui.styled.accordion {
    width: 100%;
}
#content .ui.accordion .title:not(.ui) {
    font-family: inherit;
}

/* ------------------------------------------------------------------------- */

#content ul.typo3-messages {
    padding: 0;
}
#content ul.typo3-messages li {
    margin: 0;
    padding: 0;
}
#content ul.typo3-messages li:before {
    display : none;
}
#content ul.typo3-messages li p {
    padding: .75em 1em;
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: $sm-break-point) {
	.ce-right .ce-gallery {
		float: unset;
	}
}
