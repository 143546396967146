.text-left { text-align: left }
.text-right { text-align: right }
.text-center { text-align: center }

// mimic these bootstrap classes
h1, .h1,
h2, .h2,
h3, .h3 {
	letter-spacing:var(--tracking);
}
h1, .h1 {
	font-size:var(--size-step-6)
}
h2, .h2 {
	font-size:var(--size-step-5)
}
h3, .h3 {
	font-size:var(--size-step-4)
}
h4, .h4 {
	font-size:var(--size-step-3)
}
h5, .h5 {
	font-size:var(--size-step-2)
}
h6, .h6 {
	font-size:var(--size-step-1)
}

small, .text_small {font-size:var(--size-step-0);}

@media screen and (max-width: $md-break-point) {
	h1, .h1,
	h2, .h2,
	h3, .h3,
	h4, .h4,
	h5, .h5,
	h6, .h6 {
		font-weight: 600;
	}

}


.btn {
	display: inline-block;
	border: 1px solid black;
	padding: 0.1rem;
}

h2 .btn-primary {
	font-size: var(--size-step-1);
}

.btn-primary {
	background-color: #FFB94D;
	color: #fff;
	border: 1px solid #F6B958;
	border-radius: 2px;
	padding: .5em 2.5em;
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.025);
	text-decoration : none;
	transition: all 0.05s ease-in-out;
}
.btn-primary:hover {
	background-color: #fff;
	color: #FFB94D;

}

.btn-secondary {
	background-color: #000;
	color: #fff;
	border: 1px solid #000;
	border-radius: 2px;
	padding: .5em 2.5em .75em;
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.025);
	text-decoration : none;
	transition: all 0.05s ease-in-out;
	height: 42px;
	line-height : 42px;
}
.btn-secondary:hover {
	background-color: #fff;
	color: #000;

}

.btn-more {
	margin: 2rem 0;
	color: inherit;
	border: 0;
	text-decoration : none;
	transition: all 0.05s ease-in-out;
	position : relative;
}
.btn-more:after {
	content: "";
	width: 50%;
	height: 2px;
	position : absolute;
	top: 100%;
	left: 0;
	background-color: #FFB94D;
	transition: all 0.05s ease-in-out;
}

.orange-bg .btn-more:after {
	background-color: #fff;
}
.btn-more:hover:after {
	width: 100%;
}

.red {
	color: #f00;
}
