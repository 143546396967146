/* ------------------------------------------------------------------------- */ /* header */


#header {
	background:#fff;
	color: #cfcfcf;
	width:100%;
	height: 112px;
	position:fixed;
	top:0;
	z-index:2;
	transition: background .2s ease-in-out;
	display: flex;
	flex-flow: column;
}
.sticky #header {
	background-color: #fff;
	box-shadow: 0 0 32px #0001;
	transition: background .02s ease-in-out;
}

#header:after {
	position : absolute;
	bottom: -10px;
	content: "";
	display : block;
	width: 100%;
	height: 10px;
	background-color: transparent;
	background-image: linear-gradient(90deg, #E72462 0%, var( --e-global-color-primary ) 100%);
}

@media screen and (max-width: $lg-break-point) {
	#header {
		overflow: hidden;
	}
}


.header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0;
	transition: padding .2s ease-in-out;
}

.sticky .header {
	padding: 0;
}

@media screen and (max-width: $sm-break-point) {
	.header {
		padding: 0;
	}
	#header {
		height: 90px;
	}
	#header:after {
		position : absolute;
		bottom   : 0;
	}
}


