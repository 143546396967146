.gc-icon {
	text-align : center;
}

.gc-icon img {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background-color : #fff;
}

.gc-icon .title {
	text-transform : uppercase;
}
