@import "../../../../../../../node_modules/@splidejs/splide/src/css/themes/default";

.splide {
	height: 800px;
	overflow : hidden;
}

.splide__slide {
	position : relative;
	width: 100%;
	height: 800px;
	overflow : hidden;
}

.splide__slide .inner {
	position : absolute;
	bottom: 40px;
	left: 40px;
}

.splide__slide .img {
	position : absolute;
	top: 0;
	width: 100%;
	height: auto;
}

.splide__slide .inner .heading {
	color: var(--e-global-color-secondary);
	font-family: "Karla", Sans-serif;
	font-size: 62px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 62px;
	margin-bottom : 1rem;
}

.splide__slide .inner .description {
	display: inline;
	padding: 3px 10px;
	background-image: linear-gradient(90deg, #E72462 0%, var( --e-global-color-primary ) 100%);
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
	color: #FFFFFF;
	font-family: "Poppins", Sans-serif;
	font-size: 24px;
	font-weight: bold;
	font-style: oblique;
	line-height: 38px;
}

.splide__pagination__page.is-active {
	background-color: var( --e-global-color-secondary );
}

.splide__arrow {
	width: 3em;
	height: 3em;
	background-color: var( --e-global-color-secondary );
}
.splide__arrow--prev {
	left: 1em;
}
.splide__arrow--next {
	right: 1em;
}
.splide__arrow svg {
	fill: #fff;
}

@media screen and (max-width: $sm-break-point) {
	.splide__arrow {
		top: 95%;
	}
	.splide__pagination {
		bottom: 3.5%;
	}
	.splide {
		height: 600px;
	}
	.splide__slide .img {
		position : relative;
	}
	.splide__slide .inner {
		bottom: unset;
		left: 1em;
		padding-top: 1em;
	}
	.splide__slide .inner .heading {
		padding-top: .5em;
		font-size : 32px;
		line-height : 32px;
	}
	.splide__slide .inner .description {
		font-size : 20px;
		line-height : 36px;
	}
}

