/*
 best-practice breakpoints
 */
$xs-break-point:   320px;
$sm-break-point:   575px;
$md-break-point:   768px;
$lg-break-point:   992px;
$xl-break-point:  1280px;
$xxl-break-point: 1410px;
$hd-break-point:  1920px;

:root {
	--e-global-color-primary: #864289;
	--e-global-color-secondary: #E72462;
	--e-global-color-text: #000000;
	--e-global-color-accent: #61CE70;
	--e-global-typography-primary-font-family: "Poppins";
	--e-global-typography-primary-font-weight: 600;
	--e-global-typography-secondary-font-family: "Roboto Slab";
	--e-global-typography-secondary-font-weight: 400;
	--e-global-typography-text-font-family: "Poppins";
	--e-global-typography-text-font-size: 18px;
	--e-global-typography-text-font-weight: 400;
	--e-global-typography-accent-font-family: "Roboto";
	--e-global-typography-accent-font-weight: 500;
	color: #000000;

	--font-base: "Poppins",sans-serif;

}
//     --color-dark:#404040;
//     --color-light:#f3f3f3;
//     --color-light-glare:#fff;
//     --color-primary:#0042bf;
//     --color-primary-glare:#d8e2f4;
//     --color-secondary:#ee5141;
//     --color-secondary-glare:#ffe3e5;
//     --space-xs:clamp(0.75rem,0.69rem + 0.29vw,0.9375rem);
//     --space-s:clamp(1rem,0.92rem + 0.39vw,1.25rem);
//     --space-m:clamp(1.5rem,1.38rem + 0.58vw,1.875rem);
//     --space-l:clamp(2rem,1.84rem + 0.78vw,2.5rem);
//     --space-xl:clamp(3rem,2.77rem + 1.17vw,3.75rem);
//     --space-2xl:clamp(4rem,3.69rem + 1.55vw,5rem);
//     --space-3xl:clamp(6rem,5.53rem + 2.33vw,7.5rem);
//     --space-xs-s:clamp(0.75rem,0.59rem + 0.78vw,1.25rem);
//     --space-s-m:clamp(1rem,0.73rem + 1.36vw,1.875rem);
//     --space-m-l:clamp(1.5rem,1.19rem + 1.55vw,2.5rem);
//     --space-l-xl:clamp(2rem,1.46rem + 2.72vw,3.75rem);
//     --space-l-2xl:clamp(2rem,1.07rem + 4.66vw,5rem);
//     --space-xl-2xl:clamp(3rem,2.38rem + 3.11vw,5rem);
//     --space-2xl-3xl:clamp(4rem,2.91rem + 5.44vw,7.5rem);
//
//     // https://www.type-scale.com,
//     // https://www.fluid-type-scale.com/calculate?minFontSize=18&minWidth=320&minRatio=1.125&maxFontSize=18&maxWidth=1280&maxRatio=1.2&steps=0%2C1%2C2%2C3%2C4%2C5%2C6&baseStep=1&prefix=size-step&decimals=3&useRems=on&remValue=16&previewFont=Open+Sans
//     --size-step-0: clamp(1rem, -0.104vw + 1.021rem, 0.938rem);
//     --size-step-1: clamp(1.125rem, 0vw + 1.125rem, 1.125rem);
//     --size-step-2: clamp(1.266rem, 0.141vw + 1.238rem, 1.35rem);
//     --size-step-3: clamp(1.424rem, 0.327vw + 1.358rem, 1.62rem);
//     --size-step-4: clamp(1.602rem, 0.57vw + 1.488rem, 1.944rem);
//     --size-step-5: clamp(1.802rem, 0.885vw + 1.625rem, 2.333rem);
//     --size-step-6: clamp(2.027rem, 1.287vw + 1.77rem, 2.799rem);
//
//     --font-serif:Georgia,sans-serif;
//     --gutter:var(--space-s-m);
//     --border-radius:var(--size-step-1);
//     --transition-base:250ms ease;
//     --transition-movement:200ms linear;
//     --transition-fade:200ms ease;
//     --transition-bounce:500ms cubic-bezier(0.5,0.05,0.2,1.5);
//     --tracking:-0.05ch;
//     --tracking-s:-0.075ch

