.pageheader {
	padding: 50px 0;
	text-align: center;
}

.pageheader h1 {

}
.pageheader h2 {
	font-weight: 400;
}
.pageheader .text {
	font-weight: 300;
	font-size: var(--size-step-4);
}



#c16.ce-wrapper {
	display : flex;
	flex-flow: wrap;
	gap: 20px;
	padding: 20px 0;
}

.psz_faq {
	width: calc(33% - 10px);
	min-height: 303px;
	background : linear-gradient(130deg, var( --e-global-color-secondary ) 0%, var( --e-global-color-primary ) 100%);
}
@media screen and (max-width: $sm-break-point) {
	.psz_faq {
		width: 100%;
	}
}


.psz_faq a {
	display : flex;
	width: 100%;
	height: 100%;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	color          : #FFFFFF;
	font-family    : "Karla", Sans-serif;
	text-align : center;
	text-decoration : none;
}

.psz_faq .image {
	margin: 1em auto;
	display : block;
	width: 70px;
	height: 70px;
}

.psz_faq .image.icon-cross    { background-image : url(../img/icon-cross.png); }
.psz_faq .image.icon-heart    { background-image : url(../img/icon-heart.png); }
.psz_faq .image.icon-line     { background-image : url(../img/icon-line.png); }
.psz_faq .image.icon-people   { background-image : url(../img/icon-people.png); }
.psz_faq .image.icon-pump     { background-image : url(../img/icon-pump.png); }
.psz_faq .image.icon-question { background-image : url(../img/icon-question.png); }


.psz_faq .heading {
	font-size      : 23px;
	font-weight    : 600;
	text-transform : uppercase;
	line-height    : 24px;
}

.psz_faq .more {
	padding: 0;
}

.psz_faq .gdesc {
	display : none;
}


#u9 .ce-wrapper {
	padding: 4rem 1rem;
}

.psz_termin {
	width: calc(50% - 10px);
	min-height: 303px;
	background : linear-gradient(130deg, var( --e-global-color-secondary ) 0%, var( --e-global-color-primary ) 100%);
}
.psz_termin:hover {
	background : linear-gradient(130deg, var( --e-global-color-primary ) 0%, var( --e-global-color-secondary ) 100%);
}

@media screen and (max-width: $sm-break-point) {
	#u9 .psz_termin {
		width: 100%;
		min-height: auto;
		margin: 0 0 20px;
	}
}


.psz_termin a {
	display : flex;
	width: 100%;
	height: 100%;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	color          : #FFFFFF;
	font-family    : "Karla", Sans-serif;
	text-align : center;
	text-decoration : none;
	font-size : 1.5rem;
}




.gc-header {
	position: relative;
}

.gc-header .inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
}

.gc-header .inner .heading {
	color: #E72462;
	font-size: .8rem;
	position: absolute;
	bottom: 0;
	right: 0;
	padding: .25rem .75rem;
}

.gc-header .inner .alt {
	color: #fff;
	background-color: #E72462;
	padding: 1rem;
	text-transform: uppercase;
	font-size: 1.2rem;
	line-height: 1.2;
	font-weight: 600;
	max-width: 25%;
	position: absolute;
	bottom: 3rem;
	right: 0;
}


.gc-header .inner .description {
	font-size: 5rem;
	line-height: 1.1;
	font-weight: 600;
	color: #fff;
	padding: 5rem;
}

@media screen and (max-width: 60rem) {
	.gc-header .inner {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		display: block;
	}
	.gc-header .inner .heading {
		position: relative;
		bottom: unset;
	}
	.gc-header .inner .alt {
		color: #fff;
		background-color: #E72462;
		padding: 1rem;
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 1.2;
		font-weight: 600;
		max-width: 100%;
		position: relative;
		bottom: unset;
	}
	.gc-header .inner .description {
		font-size: 3rem;
		color: #E72462;
		padding: 4rem 1rem 1rem;
		position: relative;
	}
}



.gc-123 {
	padding: 2rem 4rem;
	position: relative;
	font-size: 1.5rem;
	font-weight: 700;
	color: #E72462;
	line-height: 1.1;
	width: 33%;
}

.gc-123:nth-child(2) {
	text-align: center;
}

.gc-123 img {
	width: 100px;
	position: absolute;
	top: 1rem;
	right: 0;
}
.gc-123:nth-child(3) img {
	top: 3rem;
}


@media screen and (max-width: 60rem) {
	#c138 + div p {
		line-height: 1.5rem;
	}
	.gc-123 {
		padding: 2rem .5rem 4rem .5rem;
		width: 100%;
	}
	.gc-123:nth-child(2) {
		text-align: left;
	}
	.gc-123 img {
		top: 7rem;
		right: unset;
		left: 2rem;
	}
	.gc-123:nth-child(2) img {
		top: 5rem;
	}
	.gc-123:nth-child(3) img {
		top: 7rem;
	}
}





.gcslider .title {
	text-align: center;
	font-weight: 600;
	font-size: 1.5rem;
}

.splide__list {
	margin-bottom: 1rem;

}

.splide.multiple, .splide__slide {
	height: auto;
}

.splide__slide {
	padding: 1rem 1rem 2.5rem;
}

.splide.multiple img {
	border-radius: 1.5rem;
}

.gcslider .text {
	margin: 1rem 0;
	text-align: center;
}



.gc-icon {
	text-align: center;
}

.gc-icon .icon {
	padding: 1.5rem ;
	width: 7.5rem;
	background-color: #fff;
	border-radius: 50%;
	margin: 0 auto 1rem;
}

.gc-icon .title {
	margin: .25rem .5rem;
	text-transform: uppercase;
	font-weight: 600;
	color: #fff;
}

.content .gc-icon .txt p {
	font-size: .9rem;
}
@media screen and (max-width: 60rem) {
	.gc-icon {
		padding-bottom: 4rem;
	}
}

.gc-thankyou {
	padding: 3rem;
	text-align: center;
	font-size : 1.5rem;
}

